import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AttachmentsDetailComponent } from 'src/app/attachments/attachments-detail/attachments-detail.component';
import { AttachmentsService } from 'src/app/attachments/attachments.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponentTableComponent } from 'src/app/core/view/component/table/view-component-table.component';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { LoginService } from 'src/app/login/login.service';
import { MailingDetailComponent } from 'src/app/mailing/mailing-detail/mailing-detail.component';
import { MapsDetailComponent } from 'src/app/maps/maps-detail/maps-detail.component';
import { TasksDetailComponent } from 'src/app/tasks/tasks-detail/tasks-detail.component';
import { TasksService } from 'src/app/tasks/tasks.service';
import { TransactionsDetailComponent } from 'src/app/transactions/transactions-detail/transactions-detail.component';
import { TransactionsService } from 'src/app/transactions/transactions.service';
import { UsersService } from 'src/app/users/users.service';
import { ContactsService } from '../organizations-detail/organizations-contacts-detail/contacts.service';
import { OrganizationsContactsDetailComponent } from '../organizations-detail/organizations-contacts-detail/organizations-contacts-detail.component';
import { OrganizationsDetailGeneralComponent } from '../organizations-detail/organizations-detail-general/organizations-detail-general.component';
import { OrganizationsDetailComponent } from '../organizations-detail/organizations-detail.component';
import { MeetingsService } from '../organizations-detail/organizations-meetings-detail/meetings.service';
import { OrganizationsMeetingsDetailComponent } from '../organizations-detail/organizations-meetings-detail/organizations-meetings-detail.component';
import { OrganizationsService } from '../organizations.service';
import { MailMarketingNotificationToStatsComponent } from 'src/app/mail-marketing/mail-marketing-notification-to-stats/mail-marketing-notification-to-stats.component';
import { TranslateService } from '@ngx-translate/core';
import { CountriesService } from '../../configuration/countries/countries.service';
import { CampaignsOrganizationsService } from 'src/app/campaigns/campaigns-organizations.service';

@Component({
  selector: 'app-organizations-view',
  templateUrl: './organizations-view.component.html',
  styleUrls: ['./organizations-view.component.scss']
})
export class OrganizationsViewComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public organization: any = {};
  public country: any = {};
  public responsible: any = {};
  //Params Contacts:
  public contacts: any[];
  public contactsTotalRecords: any;
  public contactsLoading = false;
  public contactsLastGridEvent: any;
  //Params Meetings:
  public meetingsLoading: boolean;
  public meetingsLastGridEvent: any;
  public meetingsTotalRecords: any;
  public meetings: any;
  public view_map_button: boolean;

  public tasksFilters: any = {
    is_finished: false,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private organizationsService: OrganizationsService,
    private countriesService: CountriesService,
    private campaignOrganizationsService: CampaignsOrganizationsService,
    private transactionsService: TransactionsService,
    private attachmentsService: AttachmentsService,
    private loginService: LoginService,
    private tasksService: TasksService,
    private messageService: MessageService,
    private usersService: UsersService,
    private contactsService: ContactsService,
    private meetingsService: MeetingsService,
    private coreDialogService: CoreDialogService, private viewsService: ViewsService,
    private translateService: TranslateService
    //private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.view_map_button = this.loginService.hasPermission("VIEW_MAP_BUTTON");
    this.route.params.subscribe(params => {
      this.organization.id = params['id'];
      this.loadData(params['id']);
    });
  }

  loadData(id: number) {
    this.organization.is_finished = false;
    if (id != 0) {
      this.organizationsService.get(id).subscribe({
        next: (data: any) => {
          this.organization = data;
          this.organization.is_finished = false;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  loadContacts($event) {
    this.view.fireEvent("organizations.preview.contacts", "reload");
  }
  loadMeetings($event) {
    this.view.fireEvent("organizations.preview.meetings", "reload");
  }
  edit() {
    const ref = this.dialogService.open(OrganizationsDetailGeneralComponent, {
      data: {
        id: this.organization.id,
      },
      // header: "Detalles de la organización",
      width: '70%',
      /*baseZIndex: 999*/
    });
    ref.onClose.subscribe((data: any) => {

      if (data) this.loadData(this.organization.id);
      this.viewsService.changeView(this.view.code);
    })

  }
  viewContact(contact) {
    const currentUrl = this.router.url;
    const navigationExtras: NavigationExtras = {
      queryParams: {
        returnUrl: currentUrl
      }
    };

    //this.router.navigate(["/organizations/" + this.organization.id + "/contacts/" + (contact ? contact.id : 0)]);
    this.router.navigate(["/contacts/" + (this.organization.id + "/") + (contact ? contact.id : 0)], navigationExtras);
  }
  deleteContact(contact) {

    this.coreDialogService.confirm({
      message: this.translateService.instant("component.organizations.delete_message_contact", { name: contact.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.contactsService.delete(contact.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.loadContacts(this.contactsLastGridEvent);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    })
  }
  viewMeeting(meeting) {
    var params = {
      id: this.organization.id,
      contactId: meeting ? meeting.contact_id : 0,
      meetingId: meeting ? meeting.id : 0,
      deactivated: true
    }
    // Utiliza router.navigate y pasa el objeto de parámetros
    this.router.navigate(['/meetings/', (meeting ? meeting.id : 0)], { queryParams: params });
  }

  deleteMeeting(meeting) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.organizations.delete_message_meeting", { subject: meeting.subject }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.meetingsService.delete(meeting.id).subscribe(
          data => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.loadMeetings(this.meetingsLastGridEvent);
          },
          error => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        )
      },
      reject: () => {

      }
    })
  }
  viewTask(task) {
    this.openTask(task, null, null);
  }

  openTask(task, prev, next) {
    var params2: any = {};
    var params = {
      _sortBy: "task_action_scheduled_date",
      _sortDirection: "asc"
    };
    params = Object.assign(params, this.tasksFilters);
    if (params != null) {
      Object.keys(params).forEach(key => {
        if (typeof (params[key]) != "undefined" && params[key] != null) params2[key] = params[key];
      });
    }

    params2['organization_id'] = this.organization.id;
    // Convierte el objeto params a una cadena JSON
    var paramsString = JSON.stringify(params2);

    // Guarda la cadena JSON en el localStorage con la clave "organization.tasks"
    localStorage.setItem("organization.tasks", paramsString);
    localStorage.removeItem("tasks.list");
    localStorage.removeItem("home.tasks");

    this.router.navigate(['/tasks/', this.organization.id, (task?.id ? task.id : 0)]);
  }

  loadPrevOrNext(id: number) {
    let table: ViewComponentTableComponent = this.view.getComponent("organizations.preview.tasks");
    let prev: any = null;
    let next: any = null;
    let task: any = null;
    for (let i = 0; i < table.rows.length; i++) {
      if (table.rows[i].id == id) {
        task = table.rows[i];
        if ((i + 1) <= (table.rows.length - 1)) next = table.rows[i + 1];
        break;
      }
      prev = table.rows[i];
    }
    if (task != null) this.openTask(task, prev, next);
  }

  deleteTask(task) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.organizations.delete_message_task", { subject: task.subject }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.tasksService.delete(task.id).subscribe(
          data => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("organizations.preview.tasks", "reload");
            this.view.fireEvent("tasksWidget.custom", "reload");
          },
          error => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        )
      },
      reject: () => {

      }
    })
  }
  viewOpportunity(opportunity) {
    this.router.navigate(['/transactions/', 1, 0, this.organization.id, 0, (opportunity?.id ? opportunity.id : 0)]);
  }
  deleteOpportunity(opportunity) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.organizations.delete_message_opportunity"),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.transactionsService.delete(opportunity.id).subscribe(
          data => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("opportunitiesTotalPipeline.custom", "reload");
            this.view.fireEvent("opportunitiesWidget.custom", "reload");
            this.reloadTransactions();
          },
          error => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        );
      },
      reject: () => {

      }
    });
  }

  reloadTransactions() {
    this.view.fireEvent("organizations.preview.sales", "reload");
    this.view.fireEvent("organizations.preview.opportunities", "reload");
  }

  viewSale(sale) {
    this.router.navigate(['/transactions/', 2, 0, this.organization.id, 0, (sale?.id ? sale.id : 0)]);
  }
  deleteSale(sale) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.organizations.delete_message_sale"),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.transactionsService.delete(sale.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            /*this.view.fireEvent("opportunitiesWidget.custom", "reload");
            this.view.fireEvent("salesTotalPipeline.custom", "reload");
            this.reloadTransactions();*/
            this.refreshAll();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    });
  }
  viewAttachment(attachment) {
    const ref = this.dialogService.open(AttachmentsDetailComponent, {
      data: {
        id: attachment ? attachment.id : 0,
        entity_id: 1,
        entity_pk_id: this.organization.id,
        organization_id: this.organization.id
      },
      //header: attachment ? "Detalles del adjunto" : "Nuevo adjunto",
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      this.refreshAll();
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
    });
  }
  deleteAttachment(attachment) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.organizations.delete_message_attachment", { filename: attachment.filename }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.attachmentsService.delete(attachment.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("organizations.preview.attachments", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => { }
    })
  }

  newMail(emailAcc, entity_id, entity_pk_id, template_type_code, searchRelated) {

    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        to: emailAcc,
        entity_id: entity_id,
        entity_pk_id: entity_pk_id,
        organization_id: this.organization.id,
        template_type_code: template_type_code,
        searchRelated: searchRelated
      },
      header: this.translateService.instant("general.new_mail_title"),
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      this.view.fireEvent("organizations.preview.mails", "reload");
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
    });
  }

  goToCalendar() {
    this.router.navigate(['/calendar', this.organization.id]);
  }

  downloadFile(attachment) {
    this.attachmentsService.download(attachment.guid);
  }
  reorderPositions(dragIndex, dropIndex, dragItem, dropItem) {
    dragItem.position = dragIndex;
    dropItem.position = dropIndex;
  }

  setMarker() {
    /*if(this.organization.lat == 0 || this.organization.lng == 0){
      this.messageService.add({ closable: false, severity: "error", detail: "No se pudo identificar la ubicación en el mapa. Las coordenadas 'latitud' o 'longitud' no son correctas o la dirección está mal escrita." });
      return;
    }*/
    if (!this.view_map_button) return;
    const ref = this.dialogService.open(MapsDetailComponent, {
      data: {
        lat: this.organization.lat,
        lng: this.organization.lng,
        organization: this.organization
      },
      header: "Mapa",
      width: '50%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      if (data) {
        this.organization = data;
        this.organizationsService.save(this.organization.id, this.organization).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.organizations.position_map_updated") });
          },
          error: (error: any) => { }
        });
      }
    })
  }

  viewMail(data) {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        mail_id: data.notification_email_id,
        organization_id: this.organization.id,
        entity_id: data.notificationEmails.entity_id,
        //template_type_id: 1,
        template_type_code: "MAIL",
        notification_to_id: data.id,
        searchRelated: true
      },
      header: this.translateService.instant("general.new_mail_title"),
      width: '70%',
    });
    ref.onClose.subscribe((data) => {

      this.view.fireEvent("organizations.preview.mails", "reload");
    });
  };

  resendMail(data) {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        resendMail: true,
        mail_id: data.notification_email_id,
        to: null,
        //template_type_id: 1,
        template_type_code: "MAIL",
        is_generic: true,
        searchRelated: true
      },
      header: this.translateService.instant("general.resend_mail_title"),
      width: '70%',
    });
    ref.onClose.subscribe((data) => {

      this.view.fireEvent("organizations.preview.mails", "reload");
    });
  }

  seeFinalizedTasks(seeFinalizedTasks) {
    if (seeFinalizedTasks) {
      this.organization.is_finished = true;
    } else {
      this.organization.is_finished = false;
    }
    this.view.fireEvent("organizations.preview.tasks", "reload");
  }


  viewTransactions(event: any, transactionTypeId, code) {
    this.router.navigate(['/transactions/', transactionTypeId, 0, this.organization.id, 0, (event?.data?.id ? event.data.id : 0)]);
  }

  deleteTransactions(transaction) {
    var code = transaction.transactionType.code;

    this.coreDialogService.confirm({
      message: this.translateService.instant("component.organizations.delete_message_transaction", { transactionType: transaction.transactionType.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.transactionsService.delete(transaction.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("organizations.preview.opportunities", "reload");
            this.view.fireEvent("organizations.preview.sales", "reload");
            this.view.fireEvent(code, "reload");
          },
          error: (error: any) => {
            this.messageService.add({ severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => { }
    });
  }

  refreshAll() {
    this.view.fireEvent("organizations.preview.follow_up_opportunities", "reload");
    this.view.fireEvent("organizations.preview.follow_up_sales", "reload");
    this.view.fireEvent("salesWidget.custom", "reload");
    this.view.fireEvent("opportunitiesWidget.custom", "reload");
    this.view.fireEvent("salesTotalPipeline.custom", "reload");
    this.view.fireEvent("organizations.preview.attachments", "reload");
    this.view.fireEvent("organizations.preview.mails", "reload");
    this.view.fireEvent("organizations.preview.tasks", "reload");
    this.view.fireEvent("organizations.preview.opportunities", "reload");
    this.view.fireEvent("organizations.preview.contacts", "reload");
    this.view.fireEvent("organizations.preview.sales", "reload");
    this.view.fireEvent("organizations.preview.meetings", "reload");
    this.loadData(this.organization.id);
    this.view.fireEvent("organizations.preview.general", "reload");
    this.view.fireEvent("organizations.campaigns", "reload");
  }

  openEmails() {
    //this.router.navigate(["/organizations", this.organization.id, "emails"]);
    this.router.navigate(["/emails", this.organization.id]);
  }

  openNotificationStats() {
    const ref = this.dialogService.open(MailMarketingNotificationToStatsComponent, {
      data: {
        organization_id: this.organization.id,
      },
      header: this.translateService.instant("component.organizations.report_clicks"),
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      //nothing
    })
  }

  unlinkCampaign(campaign) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.organizations.unlink_campaign", { name: campaign.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.campaignOrganizationsService.unlinkOrganization({ organization_id: this.organization.id, campaign_id: campaign.id }).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.refreshAll();
            //this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    });
  }
  openCampaign(campaign) {
    window.open("/campaigns/" + (campaign.id ? campaign.id : 0));
  }
  onEvent(event: any) {
    if (event.component == "organizations.preview.general") {
      if (event.event == "action" && event.action.name == "openNotificationStats") this.openNotificationStats();
      if (event.event == "action" && event.action.name == "openEmails") this.openEmails();
      if (event.event == "action" && event.action.name == "edit") this.edit();
      if (event.event == "fieldAction" && event.action.name == "new-mail") {
        var toInfo = { name: "", email: event.value }
        if (event.action.param == "INTERNAL") {
          //Cargamos los datos del usuario (o responsible_id o responsible_user_id)
          this.usersService.get(event.value).subscribe({
            next: (data: any) => {
              toInfo = { name: data.name, email: data.email }
              this.newMail(toInfo, 1, this.organization.id, "MAIL_INTERNAL", false);
            },
            error: (error: any) => {
              this.messageService.add({ severity: "error", detail: error.error.title });
            }
          });
        } else {
          this.newMail(toInfo, 1, this.organization.id, "MAIL", true);
        }

      }
      if (event.event == "fieldAction" && event.action.name == "set-marker") this.setMarker();
    } else if (event.component == "organizations.preview.meetings") {
      if (event.event == "dblclick") this.viewMeeting(event.data);
      if (event.event == "action" && event.action.name == "add") this.viewMeeting(null);
      if (event.event == "action" && event.action.name == "edit") this.viewMeeting(event.data);
      if (event.event == "action" && event.action.name == "delete") this.deleteMeeting(event.data);
      if (event.event == "action" && event.action.name == "calendar") this.goToCalendar();
    } else if (event.component == "organizations.preview.contacts") {
      if (event.event == "dblclick") this.viewContact(event.data);
      if (event.event == "action" && event.action.name == "add") this.viewContact(null);
      if (event.event == "action" && event.action.name == "edit") this.viewContact(event.data);
      if (event.event == "action" && event.action.name == "delete") this.deleteContact(event.data);
      if (event.event == "action" && event.action.name == "sendEmailContact") {
        this.newMail({ name: "", email: event.data.email, organization_id: event.data.organization_id, contact_id: event.data.id }, 2, event.data.id, "MAIL", true);
      }
    } else if (event.component == "organizations.preview.tasks") {
      if (event.event == "reorder") this.reorderPositions(event.data.dragIndex, event.data.dropIndex, event.dragItem, event.dropItem);
      if (event.event == "dblclick") this.loadPrevOrNext(event.data.id);
      if (event.event == "action" && event.action.name == "add") this.viewTask(null);
      if (event.event == "action" && event.action.name == "edit") this.loadPrevOrNext(event.data.id);
      if (event.event == "action" && event.action.name == "delete") this.deleteTask(event.data);
      if (event.event == "action" && event.action.name == "seeFinalizedTasks") this.seeFinalizedTasks(true);
      if (event.event == "action" && event.action.name == "hideFinalizedTasks") this.seeFinalizedTasks(false);
    } else if (event.component == "organizations.preview.opportunities") {
      if (event.event == "dblclick") this.viewOpportunity(event.data);
      if (event.event == "action" && event.action.name == "add") this.viewOpportunity(null);
      if (event.event == "action" && event.action.name == "edit") this.viewOpportunity(event.data);
      if (event.event == "action" && event.action.name == "delete") this.deleteOpportunity(event.data);
    } else if (event.component == "organizations.preview.sales") {
      if (event.event == "dblclick") this.viewSale(event.data);
      if (event.event == "action" && event.action.name == "add") this.viewSale(null);
      if (event.event == "action" && event.action.name == "edit") this.viewSale(event.data);
      if (event.event == "action" && event.action.name == "delete") this.deleteSale(event.data);
    } else if (event.component == "organizations.preview.attachments") {
      if (event.event == "dblclick") this.viewAttachment(event.data);
      if (event.event == "action" && event.action.name == "add") this.viewAttachment(null);
      if (event.event == "action" && event.action.name == "edit") this.viewAttachment(event.data);
      if (event.event == "action" && event.action.name == "delete") this.deleteAttachment(event.data);
      if (event.event == "action" && event.action.name == "download") this.downloadFile(event.data);
    } else if (event.component == "organizations.campaigns") {
      if (event.event == "action" && event.action.name == "edit") this.openCampaign(event.data);
      if (event.event == "action" && event.action.name == "unlink") this.unlinkCampaign(event.data);
    } else if (event.component == "organizations.preview.mails") {
      if (event.event == "dblclick") this.viewMail(event.data);
      if (event.event == "action" && event.action.name == "view") this.viewMail(event.data);
      if (event.event == "action" && event.action.name == "resend-mail") this.resendMail(event.data);
    } else if (event.component != "") {
      if (event.event == "dblclick") { this.viewTransactions(event, event.data.transaction_type_id, event.data.transactionType.code); }
      if (event.event == "action" && event.action.name == "add") this.viewTransactions(null, event.action.transaction_type_id, event.component);
      if (event.event == "action" && event.action.name == "edit") this.viewTransactions(event, event.data.transaction_type_id, event.code.transactionType.code);
      if (event.event == "action" && event.action.name == "delete") this.deleteTransactions(event);
    }
  }

}
