import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AttachmentsDataService } from 'src/app/attachments/attachments-data.service';
import { AttachmentsService } from 'src/app/attachments/attachments.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { CoreFormService } from 'src/app/core/forms/core-forms.service';
import { ViewsService } from 'src/app/core/view/views.service';
import { ViewComponent } from '../../core/view/view.component';
import { UserChangePasswordComponent } from '../user-change-password/user-change-password.component';
import { UserZonesDetailComponent } from '../user-zones-detail/user-zones-detail.component';
import { UserZonesService } from '../user-zones.service';
import { UserMailAccountsService } from '../users-mail-accounts-detail/user-mail-accounts.service';
import { UsersMailAccountsDetailComponent } from '../users-mail-accounts-detail/users-mail-accounts-detail.component';
import { UsersService } from '../users.service';
import { UserRolesComponent } from './user-roles/user-roles.component';
import { UserRolesService } from './user-roles/user-roles.service';
import { SearchService } from 'src/app/core/search.service';
import { LoginService } from 'src/app/login/login.service';
import { TemplatesService } from '../../configuration/templates/templates.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-users-detail',
  templateUrl: './users-detail.component.html',
  styleUrls: ['./users-detail.component.scss']
})
export class UsersDetailComponent implements OnInit {
  @ViewChild("formDetails") public formDetails: NgForm;
  @ViewChild("view") public view: ViewComponent;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  public user: any = {
    emails_signature: ""
  };
  public selectedZones: any[] = [];
  public selectedRoles: any[] = [];
  public selectedMailAccounts: any = [];
  public responsibles: any[] = [];
  public image_source: any;
  private image: File;
  public imageSrc: any;
  public emails_cc: any[] = [];
  public languages: any[] = [];

  public searchResults: any[] = [];
  public disableFields: boolean = false;
  public isSuperAdmin: boolean = false;
  public user_write: boolean = false;
  tinyMceConfig: any;
  public showTinyMCE: boolean = false;

  constructor(
    private usersService: UsersService,
    private userZonesService: UserZonesService,
    private userRolesService: UserRolesService,
    private userMailAccountsService: UserMailAccountsService,
    private attachmentsService: AttachmentsService,
    private viewsService: ViewsService,

    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private dialogService: DialogService,
    private config: DynamicDialogConfig,
    private attachmentsDataService: AttachmentsDataService,
    private coreFormService: CoreFormService,
    private searchService: SearchService,
    private loginService: LoginService, public templateService: TemplatesService,
    private translateService: TranslateService,
    //private languagesService: languagesService
  ) {
    this.isSuperAdmin = (localStorage.getItem("isSuperAdmin") === 'false' ? false : true);
    this.user_write = this.loginService.hasPermission("USERS_WRITE") || this.loginService.hasPermission("USERS_SUPERADMIN");//this.loginService.canEditModel(this.user, this.view?.entity?.code);

  }

  ngOnInit(): void {
    this.configureTinyMce();
    this.loadUser(this.config.data.id);
    this.loadResponsibles();

  }
  configureTinyMce() {
    this.showTinyMCE = true;
    const that = this;
    this.tinyMceConfig =
    {
      height: 250,
      menubar: true,
      toolbar: [
        'undo redo | bold italic underline | fontselect fontsizeselect fontsize fontfamily  formatselect |' +
        'alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist |' +
        'forecolor casechange permanentpen formatpainter removeformat | fullscreen | insertfile image media pageembed link | table quickbars | variablesButton  variablesLinesButton | previewButton'
      ],
      toolbar_mode: 'wrap',
      language: 'es',
      paste_data_images: false,
      file_picker_types: 'image',
      /* and here's our custom image picker*/
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = function (e) {
          var file = e.target["files"][0];

          var reader = new FileReader();
          reader.onload = (event: any) => {
            //tenemos la url en base64. creamos el attachment
            var datafile = {
              FileName: file.name,
              ContentType: file.type,
              Length: file.size,
              b64: event.target.result
            };
            that.templateService.UploadImage(datafile).subscribe({
              next: (data: any) => {
                cb(data.location);
                //FALTA Pintar la imagen con la url en el visor.... work in progress
              },
              error: (error: any) => {
                that.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
              }
            });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      },
    };
  }

  loadUser(id) {
    if (id) {
      this.usersService.get(id).subscribe({
        next: (data: any) => {
          this.user = data;
          this.loadLanguages();
          this.loadMailsCc();
          this.attachmentsDataService.getProfile({ entity_id: 18, entity_pk_id: id }).subscribe(
            dataProfile => {
              if (dataProfile != null && dataProfile['b64']) this.image_source = 'data:image/jpeg;base64,' + dataProfile['b64'];
              if (dataProfile != null) this.user.image_id = dataProfile["attachment_id"];
              if (this.user.color == null) this.user.color = "#e8e8e8";

            },
            error => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          );
          this.selectedZones = this.user.userZones.map(m => m.zone_id);
          this.selectedMailAccounts = this.user.userMailAccounts.map(m => m.mailAccount_id);
        },
        error: (error: any) => {
          this.loadLanguages();
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      this.loadLanguages();
      this.user.activated = true;
      this.user.color = "#e8e8e8";
      //Borrar la siguiente línea si no se quiere el usuario conectado como responsable por defecto:
      //this.user.responsible_user_id = parseInt(this.config.data.responsible_id);
    }
  }

  loadMailsCc() {
    var mails = [];
    if (this.user.emails_cc != null && this.user.emails_cc != "") mails = this.user.emails_cc.split(',');
    this.emails_cc = mails.map(m => ({ "email": m }));
  }

  loadResponsibles() {
    this.usersService.combo({ excluse_self: true }).subscribe({
      next: (data: any) => {
        this.responsibles = data.rows;
      },
      error: (error: any) => {

      }
    });
  }

  addZones() {
    //Añadir zonas al usuario:
    let finalUserZones = this.selectedZones.map(zone_id => {
      let existentZones = this.user.userZones.filter(m => m.zone_id == zone_id && !m.deleted);
      if (existentZones.length > 0) {
        return existentZones[0];
      } else {
        return { user_id: this.user.id, zone_id: parseInt(zone_id) };
      }
    });
    this.user.userZones = finalUserZones;
  }

  save() {

    //No puede asignarse a si mismo.
    if (this.user.id != null && this.user.id != 0 && this.user.responsible_user_id == this.user.id) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant('component.users.detail.self_responsible_error.message') });
      return;
    }
    this.user.emails_cc = String(this.emails_cc.map(function (item) {
      return item['email'];
    }));
    if (this.user.email != null) this.user.email = this.user.email.trim();
    if (this.user.email == null || this.user.email.length == 0 || !this.validateEmail(this.user.email)) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant('component.users.detail.invalid_email_error_message') });
      return;
    }
    if (typeof this.user.id === "undefined") {
      this.usersService.add(this.user).subscribe({
        next: (data: any) => {
          this.user = data;
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant('component.users.detail.add_user_correct') });

          //Guardamos la vista desde la que se ha llamado
          this.view.fireEvent("userRole.custom", "reload", this.user);
          //this.dynamicDialogRef.close();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      })
    } else {
      this.usersService.save(this.user.id, this.user).subscribe({
        next: (data: any) => {
          this.user = data;

          //Guardamos la vista desde la que se ha llamado
          this.view.fireEvent("userRole.custom", "reload", this.user);

          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant('component.users.detail.save_user_correct') });
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.users.delete_message", { name: this.user.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.usersService.delete(this.user.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ severity: "success", detail: this.translateService.instant("general.confirmation_delete") });
            this.dynamicDialogRef.close(data);
          },
          error: (error: any) => {
            this.messageService.add({ severity: "error", detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }

  viewUserZone(userZone) {
    const ref = this.dialogService.open(UserZonesDetailComponent, {
      data: {
        user: this.user,
        selectedZones: this.selectedZones
      },
      header: this.translateService.instant('component.users.assign_zone'),
      width: '30%',
    });
    ref.onClose.subscribe((data: any) => {
      this.viewsService.changeView(this.view.code);
      if (data) {
        //this.user = data;
        this.selectedZones.push(data.zone_id);
        //this.selectedZones = this.user.userZones.map(m => m.zone_id);
        //this.loadUser(this.user.id);
        this.view.fireEvent("user.edit.zones", "reload");
      }
      this.view.fireEvent("user.edit.zones", "reload");
    });
  }

  deleteUserZone(userZone) {
    this.coreDialogService.confirm({
      message: this.translateService.instant('component.users.detail.undo_zone_assignment_confirm', { name: userZone.zone.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.userZonesService.delete(userZone.id).subscribe({
          next: (data: any) => {
            //this.selectedZones = this.user.userZones.map(m => m.zone_id);
            this.loadUser(this.user.id);
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("user.edit.zones", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => { }
    });
  }
  viewUserRole(userRole) {
    const ref = this.dialogService.open(UserRolesComponent, {
      data: {
        user: this.user,
        selectedRoles: this.selectedRoles
      },
      header: this.translateService.instant('component.users.assign_role'),
      width: '30%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      if (data) {
        this.selectedRoles.push(data.role_id);
        this.view.fireEvent("user.edit.roles", "reload");
      }
      this.view.fireEvent("user.edit.roles", "reload");
    });
  }

  deleteUserRole(userRole) {
    this.coreDialogService.confirm({
      message: this.translateService.instant('component.users.detail.undo_role_assignment_confirm', { name: userRole.role.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.userRolesService.delete(userRole.id).subscribe({
          next: (data: any) => {
            //this.selectedRoles = this.user.userRoles.map(m => m.role_id);
            this.loadUser(this.user.id);
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("user.edit.roles", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => { }
    });
  }
  viewUserMailAccount(userMailAccount) {
    const ref = this.dialogService.open(UsersMailAccountsDetailComponent, {
      data: {
        user: this.user,
        selectedMailAccounts: this.selectedMailAccounts
      },
      header: this.translateService.instant('component.users.assign_mail_account'),
      width: '30%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      if (data) {
        this.selectedRoles.push(data.mailAccount_id);
        this.view.fireEvent("user.edit.mailAccounts", "reload");
      }
      this.view.fireEvent("user.edit.mailAccounts", "reload");
    });
  }

  deleteUserMailAccount(userMailAccount) {
    this.coreDialogService.confirm({
      message: this.translateService.instant('component.users.undo_mail_account_assignment_confirm', { name: userMailAccount.mailAccount.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.userMailAccountsService.delete(userMailAccount.id).subscribe({
          next: (data: any) => {
            this.loadUser(this.user.id);
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("user.edit.mailAccounts", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => { }
    });
  }
  onErrorUpload(event) {
    this.messageService.add({ closable: false, severity: 'error', detail: this.translateService.instant('component.users.detail.error_upload') });
  }
  onBasicUpload(file, url) {
    var datafile = {
      description: this.translateService.instant('component.users.detail.datafile_photo_description', { name: this.user.name }),
      filename: file.name,
      mimetype: file.type,
      guid: file.lastModified + "-" + file.size,
      size: file.size,
      entity_id: 18,
      entity_pk_id: this.user.id
    };
    this.attachmentsService.add(datafile).subscribe(
      data => {
        var res: any = {};
        res = data;
        var dataA = {
          attachment_id: res.id,
          b64: url
        }
        //TODO: falta subir la imagen.
        this.attachmentsService.customupload(dataA).subscribe(data => {
          this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant('component.users.detail.updated_profile_message') });
        });

      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    );
  }

  changePassword() {
    const ref = this.dialogService.open(UserChangePasswordComponent, {
      data: {
        id: this.user.id,
        user: this.user
      },
      header: this.translateService.instant('component.users.detail.change_password'),
      width: '60%',
      /*baseZIndex: 999*/
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
    })
  }


  readURL(event: Event): void {
    if (event.target["files"] && event.target["files"][0]) {
      const file = event.target["files"][0];
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;

      reader.readAsDataURL(file);
    }
  }

  onClickImage() {
    if (this.image_source != null) return;
    let event = new MouseEvent('click', { bubbles: false });
    this.fileInput.nativeElement.dispatchEvent(event);
  }

  updateSource($event: Event) {
    this.projectImage($event.target['files'][0]);
  }

  //source: string = '';
  projectImage(file: File) {

    if (typeof file != "undefined") {
      if (file != null) {
        this.image = file;
      } else {
        this.user.image_id = [];
      }
      let reader = new FileReader;

      reader.onload = (e: any) => {
        this.image_source = e.target.result;
        //tenemos la url en base64. creamos el attachment         
        this.onBasicUpload(file, e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  removeImage() {
    this.image_source = null;
    this.attachmentsService.delete(this.user.image_id).subscribe(data => {
      this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant('component.users.detail.delete_image_correct') });
    });
    this.user.image_id = [];
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  isDuplied() {
    this.user.responsibleUser = null;

    //No puede asignarse a si mismo.
    if (this.user.id != null && this.user.id != 0 && this.user.responsible_user_id == this.user.id) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant('component.users.detail.self_responsible_error_message') });
      return;
    }

    /*if (!event.valid && !this.coreFormService.validate(this.formDetails)) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }*/

    if (this.user.email == null || this.user.email.length == 0 || this.user.name == null || this.user.name == "" || this.user.password == null || this.user.password == "") {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    if (this.user.email != null) this.user.email = this.user.email.trim();
    if (this.user.email == null || this.user.email.length == 0 || !this.validateEmail(this.user.email)) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant('component.users.detail.invalid_email_error_message') });
      return;
    }
    if (this.user.role_id == null) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant('component.users.detail.invalid_role_error_message') });
      return;
    }
    if (this.user.language_id == null) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant('component.users.detail.invalid_language_error_message') });
      return;
    }

    this.usersService.isDuplied(this.user).subscribe({
      next: (data: any) => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.user.detail.users_duplicates"),
            header: this.translateService.instant('general.error'),
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save();
        }
      }
    });
  }

  filterSearch(event) {
    let filtered: any[] = [];
    let query = event.query;
    if (query.length >= 4) {
      filtered.push(
        {
          email: query
        }
      );
      this.searchService.onlyPeople({ email: query }).subscribe({
        next: (data: any) => {
          data.forEach(element => {
            filtered.push(element);
          });
          this.searchResults = filtered;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }
  refreshAll() {
    this.loadUser(this.user.id);
    this.view.view?.configuration?.components?.array?.forEach(component => {
      this.view.fireEvent(component.code, "reload");
    });
  }

  loadLanguages() {
    this.usersService.allLanguages({}).subscribe({
      next: (data: any) => {
        this.languages = data.rows.map(m => m.language);
        if (this.languages.length == 1) {
          this.user.language_id = this.languages[0].id;
        }
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  onEvent(event) {
    if (event.event == "refreshAll" && event.action.name == "refreshAll") this.refreshAll();
    if (event.component == "user.edit.general") {
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.isDuplied();
      if (event.event == "action" && event.action.name == "changePassword") this.changePassword();

    } else if (event.component == "user.edit.custom") {
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.isDuplied();
      if (event.event == "action" && event.action.name == "changePassword") this.changePassword();


    } else if (event.component == "user.edit.zones") {
      if (event.event == "action" && event.action.name == "add") this.viewUserZone(null);
      if (event.event == "action" && event.action.name == "delete") this.deleteUserZone(event.data);
    } else if (event.component == "user.edit.roles") {
      if (event.event == "action" && event.action.name == "add") this.viewUserRole(null);
      if (event.event == "action" && event.action.name == "delete") this.deleteUserRole(event.data);
    } else if (event.component == "user.edit.mailAccounts") {
      if (event.event == "action" && event.action.name == "add") this.viewUserMailAccount(null);
      if (event.event == "action" && event.action.name == "delete") this.deleteUserMailAccount(event.data);
    }

    if (event.component == "view" && event.event == "entity-loaded") {

      this.config.header = typeof (this.user.id) !== "undefined" ? this.translateService.instant('component.user.detail.detail_entity', { entity_name: this.view.getEntityBaseName() }) : this.translateService.instant("component.user.detail.new_entity", { entity_name: this.view.getEntityBaseName() });
    }
  }



}
