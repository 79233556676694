import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AttachmentsDetailComponent } from 'src/app/attachments/attachments-detail/attachments-detail.component';
import { AttachmentsService } from 'src/app/attachments/attachments.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { LoginService } from 'src/app/login/login.service';
import { MailingDetailComponent } from 'src/app/mailing/mailing-detail/mailing-detail.component';
import { MeetingsService } from 'src/app/organizations/organizations-detail/organizations-meetings-detail/meetings.service';
import { TransactionsService } from 'src/app/transactions/transactions.service';
import { UsersService } from '../../users/users.service';
import { TasksStatusService } from '../tasks-status.service';
import { TasksService } from '../tasks.service';
import { CustomHistoryService } from '../../core/customHistory.service';
import { TranslateService } from '@ngx-translate/core';
import { ViewComponentTableComponent } from '../../core/view/component/table/view-component-table.component';

@Component({
  selector: 'app-tasks-detail',
  templateUrl: './tasks-detail.component.html',
  styleUrls: ['./tasks-detail.component.scss']
})
export class TasksDetailComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public viewConfig: any = {
    showCancel: false
  };
  private config: any = {};
  public task: any = {
    responsible_user_id: parseInt(this.loginService.get("userId")),
    task_action_scheduled_date: new Date(),
    is_finished: false
  };

  public action: any = {
    task_action_scheduled_date: new Date()
  };
  public tasks_status: any = [];
  public tasksHistories: any = [];
  public showNextButton: boolean = false;
  public showPrevButton: boolean = false;
  private firstTime: boolean = true;
  public task_prev_id: number;
  public task_next_id: number;
  public task_prev;
  public task_next;
  public origin;
  public tooltip_info = {
    show: false,
    mode: "",
    subject: "",
    contact: "",
    organization: "",
    responsible: "",
    last_action_date: "",
  };
  public xPos = "0px";
  public yPos = "0px";
  public entered = false;
  //TODO: Para que no se vea el botón "cancelar" de la vista:
  public componentByRoute = false;
  public dialogRef: DynamicDialogRef = null;
  public tasksFilters: any = {
    is_finished: false,
  };
  public organization_name: string = "";

  constructor(
    private injector: Injector,
    private tasksService: TasksService,
    private tasksStatusService: TasksStatusService,
    private meetingsService: MeetingsService,
    private attachmentsService: AttachmentsService,
    private transactionsService: TransactionsService,
    private loginService: LoginService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private coreDialogService: CoreDialogService,
    private viewsService: ViewsService,
    private usersService: UsersService,
    private customHistoryService: CustomHistoryService,
    private translateService: TranslateService
  ) {
    this.dialogRef = this.injector.get(DynamicDialogRef, null);
    const dialogConfig = this.injector.get(DynamicDialogConfig, null);
    if (dialogConfig) {
      this.viewConfig.showCancel = true;
      this.config = dialogConfig;
    } else {
      this.route.params.subscribe(params => {
        this.config.data = {
          id: parseInt(params['id']),
          organization_id: parseInt(params['organization_id']),
          transaction_id: parseInt(params['transaction_id'])
        };
        if (params["origin"]) {
          this.config.data.origin = params["origin"];
          this.origin = params["origin"];
        }
      });
    }
    if (this.config.data.organization_id != null && this.config.data.organization_id > 0) {
      this.task.organization_id = this.config.data.organization_id;
    }
  }

  sumDate(event) {
    this.task.task_action_scheduled_date = new Date(new Date().getTime() + (event.action.param * 24 * 60 * 60 * 1000));

    var dayOfWeek = this.task.task_action_scheduled_date.getDay();
    if (dayOfWeek === 6 || dayOfWeek === 0) {
      var message = dayOfWeek === 6 ? "sábado" : "domingo";
      dayOfWeek = (dayOfWeek === 0 ? 7 : dayOfWeek); //El domingo es 0.
      this.task.task_action_scheduled_date.setDate(this.task.task_action_scheduled_date.getDate() + (8 - dayOfWeek));

      // Mover la fecha al lunes siguiente

      this.coreDialogService.notify({
        message: this.translateService.instant("component.tasks.detail.message_non-working_day", { date: this.task.task_action_scheduled_date.getDate() + "/" + (this.task.task_action_scheduled_date.getMonth() + 1) + "/" + this.task.task_action_scheduled_date.getFullYear() }),
        header: 'Error',
        icon: 'far exclamation-circle',
        color: 'var(--orange-500)',
        accept: () => { }
      });
    }
    this.save(event);
  }

  ngOnInit(): void {
    this.loadTasksStatus();

    if (this.config.data.id != null) this.loadData(this.config.data.id);
    this.route.params.subscribe(params => {
      if (params['id'] != null) this.loadData(params['id']);
    });
  }

  loadData(id) {
    if (id != 0) {
      this.tasksService.get(id).subscribe({
        next: (data: any) => {
          //Si vuelven atras a una tarea borrada se redirecciona a crear nueva.
          if (data != null) {
            this.task = data;
            this.organization_name = data.organization.name;
            this.task.is_finished = false;
          } else {
            this.router.navigate(['/tasks/', (this.task.organization_id ? this.task.organization_id : 0), 0]);
          }
          if (this.firstTime) {
            this.change(0);//NEXT
            this.change(1);//PREV
            this.getNextPrev(false);
            this.getNextPrev(true);
            this.firstTime = false;
          }
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      var now = new Date();
      var nowMultiple = now.setMinutes(Math.ceil(now.getMinutes() / 15) * 15);
      this.task.date = new Date(nowMultiple);
      this.task.task_action_scheduled_date = new Date(nowMultiple).toISOString();
      //Estamos creando, no se muestran los botones de izq/der
      this.showNextButton = false;
      this.showPrevButton = false;
    }
  }

  loadTasksStatus() {
    this.tasksStatusService.all({_sortBy: "order"}).subscribe({
      next: (data: any) => {
        this.tasks_status = data.rows;
        //Ordenar estados de actividad por "is_default":
        //this.tasks_status.sort((a, b) => (a.is_default === b.is_default) ? 0 : a.is_default ? -1 : 1);
        this.findDefaultStatus();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  saveIfAllFormsAreValid(event: any) {
    //Valida que TODOs los formularios de la vista son válidos sino no deja guardar.
    var formsValid = this.view.getAllFormsFromViewValid();
    if (!formsValid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    } else {
      this.clearEntity();
      this.isDuplied(this.task, event);

    }
  }

  clearEntity() {

    if (this.task.user != null) this.task.user = null;
    if (this.task.organization != null) this.task.organization = null;
    if (this.task.contact != null) this.task.contact = null;
    if (this.task.transaction != null) this.task.transaction = null;
    if (this.task.meeting != null) this.task.meeting = null;
    if (this.task.taskStatus != null) this.task.taskStatus = null;
    if (this.task.taskActionType != null) this.task.taskActionType = null;
    if (this.task.createdBy != null) this.task.createdBy = null;
    if (this.task.updatedBy != null) this.task.updatedBy = null;
    if (this.task.deletedBy != null) this.task.deletedBy = null;
  }
  /*
    recoverEntity() {
      console.log("recoverEntity", this.task, this.auxTask);
      // Asigna las propiedades de auxTask a task
      this.task.user = this.auxTask.user;
      this.task.organization = this.auxTask.organization;
      this.task.contact = this.auxTask.contact;
      this.task.transaction = this.auxTask.transaction;
      this.task.meeting = this.auxTask.meeting;
      this.task.taskStatus = this.auxTask.taskStatus;
      this.task.taskActionType = this.auxTask.taskActionType;
      this.task.createdBy = this.auxTask.createdBy;
      this.task.updatedBy = this.auxTask.updatedBy;
    }*/

  save(event: any) {
    if (event != null && !event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    if (typeof this.task.id === "undefined") {
      if (this.task.transaction_id == null || this.task.transaction_id == 0) this.task.transaction_id = this.config.data.transaction_id;
      this.task.date = new Date();
      if (this.action.task_action_id != null && this.action.task_action_scheduled_date != null) {
        this.task.task_action_id = this.action.task_action_id;
        if (this.action.task_action_description != null) this.task.task_action_description = this.action.task_action_description;
        this.task.task_action_scheduled_date = this.action.task_action_scheduled_date;
      }
      this.task.task_action_description = this.task.message;

      this.tasksService.add(this.task).subscribe({
        next: (data: any) => {
          if (this.dialogRef) this.dialogRef.close(data);
          else {
            this.task = data;
            this.router.navigate(["tasks", (this.task.organization_id == null ? 0 : this.task.organization_id), this.task.id]);
            //this.loadData(this.task.id);
            //this.view.fireEvent("tasksHistoryDetail.custom", "reload", this.task);
          }
          this.messageService.add({
            closable: false, severity: 'success', summary: this.translateService.instant("component.tasks.detail.add_task_correct")
          });
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.task.task_action_description = this.task.message;
      this.tasksService.save(this.task.id, this.task).subscribe({
        next: (data: any) => {
          this.task = data;
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.tasks.detail.update_task_correct") });
          this.view.fireEvent("tasks.histories", "reload");
          this.view.fireEvent("tasksHistoryDetail.custom", "reload");
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }

  findDefaultStatus() {
    if (typeof this.task.task_status_id == "undefined") {
      this.tasks_status.forEach(element => {
        if (element.is_default) {
          this.task.task_status_id = element.id;
        }
      });
    }
  }

  cancel(data: any) {
    if (this.dialogRef) {
      this.dialogRef.close(data);
    } else {
      this.router.navigate(['/tasks']);
    }
  }

  goToCalendar() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.router.navigate(['/calendar']);
  }

  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("organization.task.detail.delete_message", { subject: this.task.subject }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.tasksService.delete(this.task.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("component.tasks.detail.delete_task_correct") });
            if (this.dialogRef != null) {
              this.dialogRef.close();
            } else {
              //this._location.back();
              if (this.customHistoryService.getBackHistory() != null) {
                if (!this.customHistoryService.getBackHistory().endsWith("/0")) {
                  this.router.navigateByUrl(this.customHistoryService.getBackHistory());
                } else {
                  if (this.config.data.organization_id != null) {
                    this.router.navigateByUrl('/organizations/0/' + this.config.data.organization_id);
                  } else {
                    this.router.navigate(['/tasks']);
                  }
                }
              } else {
                this.router.navigate(['/tasks']);
              }

            }
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    });
  }

  viewMeeting(meeting) {
    var params = {
      id: meeting ? meeting.organization_id : this.task.organization_id,
      contactId: meeting ? meeting.contact_id : this.task.contact_id,
      meetingId: meeting ? meeting.id : 0,
      taskId: this.task.id,
      deactivated: meeting ? true : false
    }
    // Utiliza router.navigate y pasa el objeto de parámetros
    this.router.navigate(['/meetings/', (meeting ? meeting.id : 0)], { queryParams: params });
  }

  deleteMeeting(meeting) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.tasks.detail.delete_message_meeting", { subject: meeting.subject }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.meetingsService.delete(meeting.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("tasks.meetings", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }
  downloadFile(attachment) {
    this.attachmentsService.download(attachment.guid);
  }

  viewAttachment(attachment) {
    const ref = this.dialogService.open(AttachmentsDetailComponent, {
      data: {
        id: attachment ? attachment.id : 0,
        entity_id: 4,
        entity_pk_id: this.task.id,
        organization_id: this.task.organization_id
      },
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.view.fireEvent("tasks.attachments", "reload");
    });
  }

  deleteAttachment(attachment) {
    this.coreDialogService.confirm({
      message: "¿Quieres borrar el adjunto " + attachment.filename + "?",
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.attachmentsService.delete(attachment.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("tasks.attachments", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    });
  }

  viewTransaction(event, data, transaction_type_id, code) {
    this.router.navigate(['/transactions/', transaction_type_id, (this.task.contact_id ? this.task.contact_id : 0), (this.task.organization_id ? this.task.organization_id : 0), this.task.id, (data?.id ? data.id : 0)]);
  }

  loadTaskTransaction() {
    if (this.task.id == null) return;
    this.tasksService.get(this.task.id).subscribe({
      next: (data: any) => {
        this.task.transaction_id = data["transaction_id"];
        this.view.fireEvent("tasks.oportunities", "reload");
        this.view.fireEvent("tasks.sales", "reload");
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }
  deleteTransaction(transaction) {
    var code = transaction.transactionType.code;
    this.coreDialogService.confirm({
      message: "¿Quieres borrar la transacción?",
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.transactionsService.delete(transaction.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("tasks.oportunities", "reload");
            this.view.fireEvent("tasks.sales", "reload");
            this.view.fireEvent(code, "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {
      }
    });
  }

  newMail(emailAcc, template_type_code, searchRelated, other_entity, other_entity_pk_id) {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        to: emailAcc,
        entity_id: (this.task.id ? 4 : other_entity),
        entity_pk_id: (this.task.id ? this.task.id : other_entity_pk_id),
        organization_id: this.task.organization_id,
        contact_id: this.task.contact_id,
        template_type_code: template_type_code,
        searchRelated: searchRelated
      },
      header: this.translateService.instant("general.new_mail_title"),
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.view.fireEvent("organizations.preview.mails", "reload");
    });
  }

  link(object, unlink, type, ask = true) {
    //El task debe desvincularse o vincularse del transaction.
    var message = "";
    var result = "";

    if (unlink) {
      switch (type) {
        //Desenlazar:
        case "meeting":
          message = this.translateService.instant("component.tasks.detail.unlink_meeting_from_task", { subject: object.subject, subject_task: this.task.subject });
          break;
        case "transaction":
          message = this.translateService.instant("component.tasks.detail.unlink_transaction_from_task", { subject: object.description, subject_task: this.task.subject });
          break;
      }
      result = this.translateService.instant("component.transaction.detail.unlink_correct");
    } else {
      //Enlazar:
      switch (type) {
        case "meeting":
          message = this.translateService.instant("component.tasks.detail.link_meeting_from_task", { subject: object.subject, subject_task: this.task.subject });
          break;
        case "transaction":

          message = this.translateService.instant("component.tasks.detail.link_transaction_from_task", { subject: object.description, subject_task: this.task.subject });
          break;
      }
      result = this.translateService.instant("component.transaction.detail.link_correct");
    };

    if (ask) {
      this.coreDialogService.confirm({
        message: message,
        icon: "pi pi-info-circle",
        accept: () => {
          if (unlink) {
            //Desenlazar:
            switch (type) {
              case "meeting":
                this.task.meeting_id = null
                break;
              case "transaction":
                this.task.transaction_id = null
                break;
            }
          } else {
            //Enlazar:
            switch (type) {
              case "meeting":
                this.task.meeting_id = object.id
                break;
              case "transaction":
                this.task.transaction_id = object.id;
                break;
            }
          };
          this.tasksService.link(this.task.id, this.task, type).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: result });
              switch (type) {
                case "meeting":
                  this.view.fireEvent("tasks.meetings", "reload");
                  break;
                case "transaction":
                  this.view.fireEvent("tasks.oportunities", "reload");
                  break;
              }
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        },
        reject: () => {
        }
      });
    } else {
      //Enlazar:
      switch (type) {
        case "meeting":
          this.task.meeting_id = object.id
          break;
        case "transaction":
          this.task.transaction_id = object.id;
          break;
      }
      this.tasksService.link(this.task.id, this.task, type).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: 'success', detail: result });
          switch (type) {
            case "meeting":
              this.view.fireEvent("tasks.meetings", "reload");
              break;
            case "transaction":
              this.view.fireEvent("tasks.oportunities", "reload");
              break;
          }
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }

  isDuplied(task, event) {
    if (event != null && !event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    this.clearEntity();
    this.tasksService.isDuplied(task).subscribe({
      next: (data: any) => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.tasks.detail.task_duplicate"),
            header: 'Error',
            icon: 'far, exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save(event);
        }
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  refreshAll() {
    this.loadData(this.task.id);
    this.view.view?.configuration?.components?.forEach(component => {
      this.view.fireEvent(component.code, "reload");
    });
  }


  openTask(task, prev, next) {
    var params2: any = {};
    var params = {
      _sortBy: "task_action_scheduled_date",
      _sortDirection: "asc"
    };
    params = Object.assign(params, this.tasksFilters);
    if (params != null) {
      Object.keys(params).forEach(key => {
        if (typeof (params[key]) != "undefined" && params[key] != null) params2[key] = params[key];
      });
    }

    params2['organization_id'] = this.task.organization_id;
    // Convierte el objeto params a una cadena JSON
    var paramsString = JSON.stringify(params2);

    // Guarda la cadena JSON en el localStorage con la clave "organization.tasks"
    localStorage.setItem("organization.tasks", paramsString);
    localStorage.removeItem("tasks.list");
    localStorage.removeItem("home.tasks");

    this.router.navigate(['/tasks/', this.task.organization_id, (task?.id ? task.id : 0)]);
  }

  loadPrevOrNext(id: number) {
    let table: ViewComponentTableComponent = this.view.getComponent("tasks.tasks");
    let prev: any = null;
    let next: any = null;
    let task: any = null;
    for (let i = 0; i < table.rows.length; i++) {
      if (table.rows[i].id == id) {
        task = table.rows[i];
        if ((i + 1) <= (table.rows.length - 1)) next = table.rows[i + 1];
        break;
      }
      prev = table.rows[i];
    }
    if (task != null) this.openTask(task, prev, next);
  }

  deleteTask(task) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.organizations.delete_message_task", { subject: task.subject }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.tasksService.delete(task.id).subscribe(
          data => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("tasks.tasks", "reload");
          },
          error => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        )
      },
      reject: () => {

      }
    })
  }
  seeFinalizedTasks(seeFinalizedTasks) {
    if (seeFinalizedTasks) {
      this.task.is_finished = true;
    } else {
      this.task.is_finished = false;
    }
    this.view.fireEvent("tasks.tasks", "reload");
  }
  viewTask(task) {
    this.openTask(task, null, null);
  }


  unlinkCampaign(campaign) {
    this.task.campaign_id = null;
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.tasks.unlink_campaign", { name: campaign.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.tasksService.save(this.task.id, this.task).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.refreshAll();
            //this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    });
  }
  openCampaign(campaign) {
    window.open("/campaigns/" + (campaign.id ? campaign.id : 0));
  }
  onEvent(event) {
    if (event.event == "closeDialog" && event.action.name == "closeDialog") this.dialogRef.close();
    if (event.event == "refreshAll" && event.action.name == "refreshAll") this.refreshAll();
    if (event.component == "tasks.edit.general") {
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel(null);
      if (event.event == "action" && event.action.name == "save") {
        this.saveIfAllFormsAreValid(event); //Validamos si todos los formularios son validos, si es así se llamará al isDuplied
      }
      if (event.event == "fieldAction" && event.action.name == "new-mail" && event.entityField.model_property == "organization.email") {
        this.newMail({ name: "", email: event.data.organization.email }, "MAIL", true, 1, event.data.organization.id);
      }
      if (event.event == "fieldAction" && event.action.name == "new-mail" && event.entityField.model_property == "contact.email") {
        this.newMail({ name: event.data.contact.fullname, email: event.data.contact.email }, "MAIL", true, 2, event.data.contact.id);
      }
      if (event.event == "onChange" && event.data != null && event.data.organization != null) {
        this.organization_name = event.data.organization.name;
      }
    } else if (event.component == "tasks.histories") {
      if (event.event == "action" && event.action.name == "save") this.saveIfAllFormsAreValid(event);//this.save(event);
      if (event.event == "action" && event.action.name == "registerTaskHistory") this.sumDate(event);
      if (event.event == "fieldAction" && event.action.name == "new-mail") {
        if (event.action.param == "INTERNAL") {
          //Cargamos los datos del usuario (o responsible_id o responsible_user_id)
          this.usersService.get(event.value).subscribe({
            next: (data: any) => {
              var toInfo = { name: data.name, email: data.email }
              this.newMail(toInfo, "MAIL_INTERNAL", false, 18, data.id);
            },
            error: (error) => {
              this.messageService.add({ severity: "error", detail: error.error.title });
            }
          });
        } else {
          this.newMail({ name: "", email: event.value }, "MAIL", false, 18, event.data.id);
        }
      }
    } else if (event.component == "tasks.meetings") {
      if (event.event == "action" && event.action.name == "add") this.viewMeeting(null);
      if (event.event == "action" && event.action.name == "edit") this.viewMeeting(event.data);
      if (event.event == "action" && event.action.name == "delete") this.deleteMeeting(event.data);
      if (event.event == "dblclick") this.viewMeeting(event.data);
      if (event.event == "action" && event.action.name == "unlink") this.link(event.data, true, "meeting");
      if (event.event == "action" && event.action.name == "link") this.link(event.data, false, "meeting");
      if (event.event == "action" && event.action.name == "calendar") this.goToCalendar();
    } else if (event.component == "tasks.attachments") {
      if (event.event == "action" && event.action.name == "add") this.viewAttachment(null);
      if (event.event == "action" && event.action.name == "delete") this.deleteAttachment(event.data);
      if (event.event == "dblclick") this.viewAttachment(event.data);
      if (event.event == "action" && event.action.name == "download") this.downloadFile(event.data);
    } else if (event.component == "tasks.oportunities") {
      if (event.event == "action" && event.action.name == "add") this.viewTransaction(event, null, 1, "oportunidad");
      if (event.event == "action" && event.action.name == "edit") this.viewTransaction(event, event.data, 1, "oportunidad");
      if (event.event == "action" && event.action.name == "delete") this.deleteTransaction(event.data);
      if (event.event == "dblclick") this.viewTransaction(event, event.data, 1, "oportunidad");
      if (event.event == "action" && event.action.name == "unlink") this.link(event.data, true, "transaction");
      if (event.event == "action" && event.action.name == "link") this.link(event.data, false, "transaction");
    } else if (event.component == "tasks.sales") {
      if (event.event == "action" && event.action.name == "add") this.viewTransaction(event, null, 2, "venta");
      if (event.event == "action" && event.action.name == "edit") this.viewTransaction(event, event.data, 2, "venta");
      if (event.event == "action" && event.action.name == "delete") this.deleteTransaction(event.data);
      if (event.event == "dblclick") this.viewTransaction(event, event.data, 2, "venta");
      if (event.event == "action" && event.action.name == "unlink") this.link(event.data, true, "transaction");
      if (event.event == "action" && event.action.name == "link") this.link(event.data, false, "transaction");
    } else if (event.component == "tasks.tasks") {

      //if (event.event == "reorder") this.reorderPositions(event.data.dragIndex, event.data.dropIndex, event.dragItem, event.dropItem);
      if (event.event == "dblclick") this.loadPrevOrNext(event.data.id);
      if (event.event == "action" && event.action.name == "add") this.viewTask(null);
      if (event.event == "action" && event.action.name == "edit") this.loadPrevOrNext(event.data.id);
      if (event.event == "action" && event.action.name == "delete") this.deleteTask(event.data);
      if (event.event == "action" && event.action.name == "seeFinalizedTasks") this.seeFinalizedTasks(true);
      if (event.event == "action" && event.action.name == "hideFinalizedTasks") this.seeFinalizedTasks(false);

    } else if (event.component == "tasks.campaigns") {
      if (event.event == "action" && event.action.name == "edit") this.openCampaign(event.data);
      if (event.event == "action" && event.action.name == "unlink") this.unlinkCampaign(event.data);
    } else if (event.component != "") {
      if (event.event == "dblclick") { this.viewTransaction(event, event.data, event.data.transaction_type_id, event.data.transactionType.code); }
      if (event.event == "action" && event.action.name == "add") this.viewTransaction(event, null, event.action.transaction_type_id, event.component);
      if (event.event == "action" && event.action.name == "edit") this.viewTransaction(event, event.data, event.data.transaction_type_id, event.data.transactionType.code);
      if (event.event == "action" && event.action.name == "delete") this.deleteTransaction(event.data);
    }

    if (event.component == "view" && event.event == "entity-loaded") {
      this.config.header = typeof (this.task.id) !== "undefined" ?
        this.translateService.instant("component.tasks.detail.detail_entity", { entity_name: this.view.getEntityBaseName() })
        :
        this.translateService.instant("component.tasks.detail.new_entity", { entity_name: this.view.getEntityBaseName() });
    }
  }

  status_selected(id) {
    //comrpobar si puedo editarlo
    if (!this.loginService.canEditModel(this.task, this.view?.entity?.code)) {
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: this.translateService.instant("component.tasks.detail.not_permission") });
      return;
    }

    this.task.task_status_id = id;

    var formsValid = this.view.getAllFormsFromViewValid();
    if (!formsValid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    this.clearEntity();
    this.save({ valid: formsValid });
  }

  change(prev) {
    //Desde navegación.
    this.route.params.subscribe(params => {
      var next_id = params["next_id"];
      var prev_id = params["prev_id"];

      if (next_id > 0) {
        this.showNextButton = true;
        if (next_id > 0) this.task_next_id = params["next_id"];
      }
      if (prev_id > 0) {
        this.showPrevButton = true;
        if (prev_id > 0) this.task_prev_id = params["prev_id"];
      }
    });

    //Desde dialog
    if (!this.config.data.params) return;
    this.config.data.params.prev = (prev == 0 ? false : true);
    if (!prev) {
      this.task_next = this.config.data.nextTask;
      this.task_next_id = this.config.data.nextTask?.id;
      this.showNextButton = (this.task_next_id ? true : false);
    }
    if (prev) {

      this.task_prev = this.config.data.prevTask;
      this.task_prev_id = this.config.data.prevTask?.id;
      this.showPrevButton = (this.task_prev_id ? true : false);
    }

  }
  loadPrevNext(prev) {
    let newLoadTaskId = null;
    if (prev && this.config.data.prevTask == null) {
      this.router.navigate(['/tasks/', (this.task_prev?.organization_id ? this.task_prev.organization_id : 0), this.task_prev_id]);
    }
    if (!prev && this.config.data.nextTask == null) {
      this.router.navigate(['/tasks/', (this.task_next?.organization_id ? this.task_next.organization_id : 0), this.task_next_id]);
    }


    if (prev && this.config.data.prevTask) {
      newLoadTaskId = this.config.data.prevTask.id;
    }
    if (!prev && this.config.data.nextTask) {
      newLoadTaskId = this.config.data.nextTask.id;
    }
    var ret = {
      mode: prev ? "prev" : "next",
      id: newLoadTaskId
    };
    if (this.config.data.prevTask || this.config.data.nextTask) this.cancel(ret);
  }
  onMouseEnterButton(mode: string) {
    if (!this.entered) {
      //this.entered sirve para que no recalcule la posición tantas veces y así no haga lenta la pantalla.
      window.addEventListener("mousemove", (e) => {
        this.xPos = (e.clientX - 145) + "px";
        this.yPos = (e.clientY - 15) + "px";
      });
      this.entered = true;
      if (mode == "next") {
        this.tooltip_info.mode = this.translateService.instant("component.tasks.detail.next_task");
        this.tooltip_info.subject = "" + this.task_next?.subject;
        this.tooltip_info.responsible = this.task_next?.user?.name;
        this.tooltip_info.contact = this.task_next?.contact?.fullname;
        this.tooltip_info.last_action_date = this.task_next?.date;
        this.tooltip_info.organization = this.task_next?.organization?.name
      } else {
        this.tooltip_info.mode = this.translateService.instant("component.tasks.detail.prev_task");
        this.tooltip_info.subject = "" + this.task_prev?.subject;
        this.tooltip_info.responsible = this.task_prev?.user?.name;
        this.tooltip_info.contact = this.task_prev?.contact?.fullname;
        this.tooltip_info.last_action_date = this.task_prev?.date;
        this.tooltip_info.organization = this.task_prev?.organization?.name
      }
      this.tooltip_info.show = true;
    }
  }
  onMouseLeaveButton() {
    this.tooltip_info.show = false;
    this.entered = false;
  }

  getNextPrev(prev) {
    // Obtiene la cadena JSON del localStorage utilizando de clave la página de donde proviene.
    var haveParamsString = false;
    var paramsStringList = localStorage.getItem("tasks.list");
    var paramsStringHome = localStorage.getItem("home.tasks");
    var paramsStringOrganization = localStorage.getItem("organization.tasks");

    var paramsString = "{}";
    if (paramsStringList != null) {
      paramsString = paramsStringList;
      haveParamsString = true;
    }
    if (paramsStringHome != null) {
      paramsString = paramsStringHome;
      haveParamsString = true;
    }
    if (paramsStringOrganization != null) {
      paramsString = paramsStringOrganization;
      haveParamsString = true;
    }

    //Si no viene de ninguna de las vistas anteriores, no mostrará los botones de next/prev task:
    if (!haveParamsString) return;

    // Convierte la cadena JSON en un objeto
    var params = JSON.parse(paramsString);

    // Limpia el objeto params eliminando los valores nulos
    for (var key in params) {
      if (params.hasOwnProperty(key) && params[key] === null) {
        delete params[key];
      }
    }
    params.prev = prev;
    params.viewedId = this.task.id;
    this.tasksService.getNextPrev(this.task.id, params).subscribe({
      next: (data: any) => {
        if (prev) {
          this.task_prev_id = data.id;
          this.task_prev = data.task;
          if (this.task_prev_id > 0) this.showPrevButton = true;
        } else {
          this.task_next_id = data.id;
          this.task_next = data.task;
          if (this.task_next_id > 0) this.showNextButton = true;
        }
      },
      error: (error) => {

      }
    });
  }
}

