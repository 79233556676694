import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { EntitiesService } from 'src/app/configuration/entities.service';
import { ContactsService } from 'src/app/organizations/organizations-detail/organizations-contacts-detail/contacts.service';
import { OrganizationsService } from 'src/app/organizations/organizations.service';
import { MailingDetailComponent } from '../../mailing/mailing-detail/mailing-detail.component';
import { NotificationsService } from '../notifications.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {

  @ViewChild("grid") public grid: Table;
  public notificationsEmails: any[];
  public totalRecords: number;
  public loading: boolean = false;
  private lastGridEvent: any;
  public filters: any = {};
  public searchOrganizationsFilters: any = {};
  public organizations: any[] = [];
  public entities: any[] = [];
  public contacts: any[] = [];

  constructor(private router: Router,
    private messageService: MessageService,
    private notificationsService: NotificationsService,
    private organizationsService: OrganizationsService,
    private entitiesService: EntitiesService,
    private contactsService: ContactsService,
    private dialogService: DialogService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.loadOrganizations();
    this.loadEntities();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  clearFilters() {
    this.filters = {};
    this.searchOrganizationsFilters = {};
    this.search();
  }

  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first / $event.rows,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc",
      allNotifications: true
    };
    params = Object.assign(params, this.filters);
    this.notificationsService.all(params).subscribe({
      next: (data: any) => {
        this.totalRecords = data.totalRows;
        this.notificationsEmails = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }

  loadNotificationsTO(notificationsEmail) {
    notificationsEmail.notificationsTo = [];
    var params = {
      _page: 1,
      _pageSize: 10000,
      _sortBy: "",
      _sortDirection: "desc"
    };

    this.notificationsService.allNotificationsTo(params).subscribe({
      next: (data: any) => {
        //this.totalRecords = data.totalRows;
        notificationsEmail.notificationsEmails = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
    notificationsEmail.notificationsTo.push({
      real_send_date: new Date(),
      state: this.translateService.instant('component.mailMarketing.list.sent_status'),
      mails: "laura.sanz@diaple.com"
    })
  }
  loadOrganizations() {
    this.organizationsService.getCombo(this.searchOrganizationsFilters).subscribe({
      next: (data: any) => {
        this.organizations = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    });
  }

  loadEntities() {
    this.entitiesService.all({}).subscribe(
      data => {
        this.entities = data.rows;
      },
      error => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    );
  }

  searchOrganization(event) {
    if (event?.filter?.length >= 2) {
      this.searchOrganizationsFilters = Object.assign(this.searchOrganizationsFilters, { text: event.filter });
      this.loadOrganizations();
    }
  }

  changeOrganization() {
    var organization_id = this.filters.organization_id;
    if (organization_id) {
      this.contactsService.all({ organization_id: organization_id }).subscribe(
        data => {
          this.contacts = data.rows;
        },
        error => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      );
    } else {
      delete this.filters.organization_id;
      delete this.filters.contact_id;
      delete this.searchOrganizationsFilters.text;
      this.loadOrganizations();
    }
  }

  viewMail(data) {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        mail_id: data.id,
        //organization_id: this.organization.id,
        entity_id: data.entity_id,
        //template_type_id: 1,
        template_type_code: "MAIL",
        //notification_to_id: data.id,
        searchRelated: true
      },
      header: this.translateService.instant("general.new_mail_title"),
      width: '70%',
    });
  };

  resendMail(data) {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        resendMail: true,
        mail_id: data.id,
        to: null,
        //template_type_id: 1,
        template_type_code: "MAIL",
        is_generic: true,
        searchRelated: true,
        entity_id: data.entity_id,
        /*entity_pk_id: this.contact.id,
        organization_id: this.contact.organization_id,
        contact_id: this.contact.id,
        template_type_id: 1*/
      },
      header: this.translateService.instant("general.resend_mail_title"),
      width: '70%',
    });
    ref.onClose.subscribe((data) => {

    });
  }

  getState(status) {
    if (status == 'sent') {
      var sent_status_string: string = this.translateService.instant('component.mailMarketing.list.sent_status');
      return "<div class='badge badge-block badge-success'>" + sent_status_string + "</div>";
    } else if (status == "pending") {
      var pending_status_string: string = this.translateService.instant('component.mailMarketing.list.pending_status');
      return "<div class='badge badge-block badge-warning'>" + pending_status_string + "</div>";
    } else if (status == "programmed") {
      var programmed_status_string: string = this.translateService.instant('component.mailMarketing.list.programmed_status');
      return "<div class='badge badge-block badge-info'>" + programmed_status_string + "</div>";
    } else if (status == "error") {
      var error_status_string: string = this.translateService.instant('component.mailMarketing.list.error_status');
      return "<div class='badge badge-block badge-danger'>" + error_status_string + "</div>";
    } else {
      var default_status_string: string = this.translateService.instant('component.mailMarketing.list.default_status');
      return "<div class='badge badge-block badge-secondary'>" + default_status_string + "</div>";
    }
  }
}
