<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
      </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/reports']">{{'component.reports.detail.reports' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li>{{report.name}}</li>
</ul>

<p-tabView (onChange)="onTabOpen($event)" [(activeIndex)]="selectTabIndex" id="reports">
  <p-tabPanel header="{{'component.reports.detail.configuration' | translate}}" leftIcon="pi pi-cog ng-star-inserted"
    [disabled]="!write_report">
    <form #formDetails="ngForm">
      <p-accordion>
        <p-accordionTab header="{{'component.reports.detail.configuration' | translate}}" [selected]="true">
          <div class="p-grid">
            <div class="p-col p-field">
              <label for="name">{{'component.reports.detail.name' | translate}}</label>
              <input name="name" type="text" pInputText [(ngModel)]="report.name" />
            </div>
            <div class="p-col-4 p-field">
              <label for="entity_id">{{'component.reports.detail.entity' | translate}} </label>
              <p-dropdown (onChange)="onChangeEntity()" name="entity_id" [options]="entities"
                [(ngModel)]="report.entity_id" optionValue="id" optionLabel="name"
                placeholder="{{'component.reports.detail.select_entity' | translate}}" [disabled]="report.id"
                [showClear]="true" appendTo="body"></p-dropdown>
            </div>
          </div>
        </p-accordionTab>
        <p-accordionTab header="{{'component.reports.detail.filters' | translate}}">

          <p-fieldset class="fieldsetReports"
            *ngFor="let groupAND of report.metadata?.filters.filtersGroupAND; let i = index">
            <ng-template pTemplate="header" *ngIf="i==0">{{'component.reports.detail.condition' |
              translate}}</ng-template>
            <ng-template pTemplate="header" *ngIf="i!=0">
              {{'component.reports.detail.and_condition' | translate}}
              <button pButton pRipple title="{{'component.reports.detail.delete_condition' | translate}}"
                (click)="deleteFilterAND(i)" type="button" icon="pi pi-times" style="height: 20px;"
                class="p-p-0 p-element p-ripple p-button-rounded p-button-danger p-button-text p-button p-component p-button-icon-only p-button-icon-only-sm "></button>
            </ng-template>
            <div class="p-grid" *ngFor="let groupOR of groupAND.filtersGroupOR; let j = index">
              <button pButton pRipple title="{{'component.reports.detail.delete_condition' | translate}}"
                (click)="deleteFilterOR(i, j)" type="button" icon="pi pi-times"
                class="p-p-0 p-mt-3 p-element p-ripple p-button-rounded p-button-danger p-button-text p-button p-component p-button-icon-only p-button-icon-only-sm "></button>
              <div class="p-col p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-field">
                <label for="code">{{'component.reports.detail.data_fields' | translate}} </label>
                <!--<p-cascadeSelect class=""
                                 [(ngModel)]="groupOR.entity_field_id"
                                 [options]="entitiesToFilter"
                                 optionLabel="description"
                                 optionValue="id"
                                 optionGroupLabel="entity"
                                 (onChange)="onFieldChange($event,i,j, 'filter')"
                                 [ngModelOptions]="{standalone: true}"
                                 [optionGroupChildren]="['fields', 'subfields']" placeholder="Selecciona">
                </p-cascadeSelect>-->
                <p-dropdown placeholder="{{'general.select' | translate}}"
                  (onChange)="onFieldChange($event,i,j, 'filter')" [(ngModel)]="groupOR.entity_field_id"
                  [options]="entitiesToFilter" [ngModelOptions]="{standalone: true}" [showClear]="true"
                  name="selectedColumns" appendTo="body" [filter]="true" optionLabel="description"
                  optionValue="id"></p-dropdown>
              </div>
              <div class="p-col p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2 p-field">
                <label for="data_type">{{'component.reports.detail.criterion' | translate}}</label>
                <p-dropdown appendTo="body" [options]="filterConditionsValues[groupOR.entity_field_id]"
                  [(ngModel)]="groupOR.condition_value" placeholder="{{'general.select' | translate}}"
                  optionLabel="name" optionValue="value" [ngModelOptions]="{standalone: true}"></p-dropdown>
              </div>
              <div class="p-col p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-field"
                *ngIf="groupOR.condition_value != 'null' && groupOR.condition_value != '!null'">
                <label for="require2">{{'component.reports.detail.value' | translate}}</label>
                <input *ngIf="getFilterOptionsOrValueType(groupOR, false) == 'input-text'"
                  [ngModelOptions]="{standalone: true}" type="text" pInputText [(ngModel)]="groupOR.value" />
                <p-dropdown *ngIf="getFilterOptionsOrValueType(groupOR, false) == 'input-dropdown'"
                  [options]="filterValuesOptions[groupOR.entity_field_id]" [(ngModel)]="groupOR.value"
                  placeholder="{{'general.select' | translate}}" optionLabel="text" optionValue="code"
                  [showClear]="true" appendTo="body" [filter]="true" (onFilter)="onFilter($event, groupOR)"
                  [ngModelOptions]="{standalone: true}"></p-dropdown>
                <p-dropdown appendTo="body" *ngIf="(getFilterOptionsOrValueType(groupOR, false)=='input-datetime')"
                  [options]="date_options" [(ngModel)]="groupOR.value_date"
                  placeholder="{{'general.select' | translate}}" optionLabel="name" optionValue="value"
                  [ngModelOptions]="{standalone: true}"></p-dropdown>
                <!--<p-calendar *ngIf="getFilterOptionsOrValueType(groupOR, false) == 'input-datetime'&&groupOR.value_date=='date'" dateFormat="dd/mm/yy" hourFormat="24" [showIcon]="true" [showTime]="true"
                            [ngModel]="groupOR.value | isoToDate" appendTo="body" [ngModelOptions]="{standalone: true}"
                            (onSelect)="dateToIso($event, i, j)"></p-calendar>-->
                <app-view-component-datetime
                  *ngIf="getFilterOptionsOrValueType(groupOR, false) == 'input-datetime' && groupOR.value_date=='date'"
                  [(ngModel)]="groupOR.value" [ngModelOptions]="{standalone: true}" [show-time]="false">
                </app-view-component-datetime>
                <input *ngIf="getFilterOptionsOrValueType(groupOR, false) == 'input-number'"
                  [ngModelOptions]="{standalone: true}" type="number" pInputText [(ngModel)]="groupOR.value" />
                <p-multiSelect *ngIf="getFilterOptionsOrValueType(groupOR, false) == 'dropdown-multiple'"
                  [options]="filterValuesOptions[groupOR.entity_field_id]" [(ngModel)]="groupOR.value_multiple"
                  optionLabel="text" optionValue="code" placeholder="{{'general.select' | translate}}" [filter]="true"
                  [showClear]="true" appendTo="body" [ngModelOptions]="{standalone: true}"></p-multiSelect>

                <p-dropdown *ngIf="getFilterOptionsOrValueType(groupOR, false) == 'switch'" [options]="yes_no"
                  [(ngModel)]="groupOR.value" placeholder="{{'general.select' | translate}}" optionLabel="name"
                  optionValue="value" [showClear]="true" appendTo="body"
                  [ngModelOptions]="{standalone: true}"></p-dropdown>

              </div>
              <div class="p-col p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-field">
                <label>{{'component.reports.detail.ask_user' | translate}}</label>
                <p-checkbox [(ngModel)]="groupOR.ask_user" binary="true"
                  [ngModelOptions]="{standalone: true}"></p-checkbox>
              </div>

            </div>
            <div class="p-col p-field">
              <label>&nbsp;</label>
              <button pButton (click)="addFilterItemOR(i)">
                <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>&nbsp;&nbsp;{{'component.reports.detail.or' |
                translate}}
              </button>
            </div>
          </p-fieldset>
          <div class="p-col p-field">
            <label>&nbsp;</label>
            <button pButton (click)="addFilterItemAND()">
              <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>&nbsp;&nbsp;{{'component.reports.detail.and' |
              translate}}
            </button>
          </div>

        </p-accordionTab>
        <p-accordionTab>
          <p-header>
            <div class="">
              <p-checkbox inputId="showReportSection" [(ngModel)]="report.metadata?.report.show" [binary]="true"
                name="showReportSection"></p-checkbox>
              <label for="showReportSection" class="p-ml-2">{{'component.reports.detail.report' | translate}}</label>
            </div>
          </p-header>
          <!-- <div class="p-grid">
            <div>Ordenación: </div>
            <div class="">{{this.report.metadata?.report?.order?.column_key}} - {{getNameEntityField(this.report.metadata?.report?.order?.column_key)}}</div>
          </div>-->
          <div class="p-grid">
            <div class="p-col p-field">
              <label for="code">{{'component.reports.detail.select_columns' | translate}}</label>

              <!--<p-dropdown placeholder="{{'general.select' | translate}}" [(ngModel)]="selectedColumns" [options]="entitiesToTable"
              [showClear]="true" name="selectedColumns" appendTo="body" [filter]="true" optionLabel="description"></p-dropdown>-->

              <p-multiSelect [options]="entitiesToTable" [(ngModel)]="selectedColumns" optionLabel="description"
                selectedItemsLabel="{0} {{'component.reports.detail.columns_selected' | translate}}"
                [style]="{'min-width': '200px'}" placeholder="{{'general.select' | translate}}"
                [ngModelOptions]="{standalone: true}"></p-multiSelect>
            </div>
            <div class="p-col p-field">
              <label>&nbsp;</label>
              <button pButton (click)="addColumns(false)" class="p-mr-2">
                <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> {{'component.reports.detail.add' | translate}}
              </button>
              <button pButton (click)="deleteAllColumns()" class="p-button-danger">
                <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon> {{'component.reports.detail.delete_all_fields' |
                translate}}
              </button>
            </div>
          </div>

          <p-table #grid [lazy]="true" [value]="this.report.metadata?.report.columns" [paginator]="false"
            [scrollable]="true" scrollHeight="flex" [reorderableColumns]="true" (onRowReorder)="onRowReorder($event)">
            <ng-template pTemplate="header">
              <tr>
                <th width="35"></th>
                <th>{{'component.reports.detail.column' | translate}}</th>
                <th>{{'component.reports.detail.name' | translate}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-index="rowIndex">
              <tr [pReorderableRow]="index">
                <td width="35">
                  <span class="pi pi-bars" pReorderableRowHandle></span>
                </td>
                <td>{{item.description}}</td>
                <td class="p-field p-pt-0 p-pb-0">
                  <input pInputText [(ngModel)]="item.label" [placeholder]="item.description"
                    (onfocus)="onFieldTableChange($event, field)" [ngModelOptions]="{standalone: true}" />
                </td>
                <td width="100">
                  <a (click)="deleteColumnItem(item)">
                    <fa-icon [icon]="['far', 'trash-alt']" class="color-danger"></fa-icon>
                  </a>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="this.report.metadata?.report.columns?.length+1">
                  {{'component.reports.detail.no_columns_selected' | translate}}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-accordionTab>
        <p-accordionTab header="{{'component.reports.detail.graphic' | translate}}" *ngIf="graphics_in_reports">
          <p-header>
            <div class="">
              <p-checkbox inputId="showGraphSection" [(ngModel)]="report.metadata?.graph.show" [binary]="true"
                name="showGraphSection"></p-checkbox>
              <label for="showGraphSection" class="p-ml-2">{{'component.reports.detail.graphic' | translate}}</label>
            </div>
          </p-header>
          <div class="p-grid">
            <div class="p-col-12" *ngIf="report.metadata?.graph.show">
              <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-field">
                <label for="graphs_types">{{'component.reports.detail.type_graph' | translate}}</label>
                <p-dropdown appendTo="body" [options]="graphs_types" [(ngModel)]="report.metadata?.graph.type"
                  placeholder="{{'general.select' | translate}}" optionLabel="name" optionValue="value"
                  [showClear]="true" name="graphs_types"></p-dropdown>
              </div>

              <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-field" style="position: relative;">
                <label for="code">{{'component.reports.detail.group_by' | translate}}</label>
                <p-dropdown placeholder="{{'general.select' | translate}}"
                  [(ngModel)]="report.metadata?.graph.entity_field_grouped.entity_field_grouped_id"
                  [options]="entitiesToFilter" [showClear]="true" name="selectedColumns" appendTo="body" [filter]="true"
                  optionLabel="description" optionValue="id" [ngModelOptions]="{standalone: true}"
                  (onChange)="onGraphChange($event,true,false)"></p-dropdown>
                <!--<a *ngIf="report.metadata?.graph.entity_field_grouped.entity_field_grouped_id" (click)="clearEntityFieldGrouped(1)" style=" position: absolute; right: 44px; bottom: 12px; "><i class=" pi pi-times"></i></a>-->

                <p-radioButton *ngIf="showDateSelector" class="p-mt-2 p-mr-2" name="groupname" value="date"
                  [(ngModel)]="report.metadata?.graph.entity_field_grouped.entity_field_grouped_type"
                  label="{{'component.reports.detail.date' | translate}}"></p-radioButton>
                <p-radioButton *ngIf="showDateSelector" class="p-mt-2 p-mr-2" name="groupname" value="datehour"
                  [(ngModel)]="report.metadata?.graph.entity_field_grouped.entity_field_grouped_type"
                  label="{{'component.reports.detail.date_hour' | translate}}"></p-radioButton>
                <p-radioButton *ngIf="showDateSelector" class="p-mt-2 p-mr-2" name="groupname" value="datetime"
                  [(ngModel)]="report.metadata?.graph.entity_field_grouped.entity_field_grouped_type"
                  label="{{'component.reports.detail.date_hour_minutes' | translate}}"></p-radioButton>
                <p-radioButton *ngIf="showDateSelector" class="p-mt-2 p-mr-2" name="groupname" value="week"
                  [(ngModel)]="report.metadata?.graph.entity_field_grouped.entity_field_grouped_type"
                  label="{{'component.reports.detail.weeks' | translate}}"></p-radioButton>
                <p-radioButton *ngIf="showDateSelector" class="p-mt-2 p-mr-2" name="groupname" value="month"
                  [(ngModel)]="report.metadata?.graph.entity_field_grouped.entity_field_grouped_type"
                  label="{{'component.reports.detail.months' | translate}}"></p-radioButton>
                <p-radioButton *ngIf="showDateSelector" class="p-mt-2 p-mr-2" name="groupname" value="trimester"
                  [(ngModel)]="report.metadata?.graph.entity_field_grouped.entity_field_grouped_type"
                  label="{{'component.reports.detail.quarters' | translate}}"></p-radioButton>
                <p-radioButton *ngIf="showDateSelector" class="p-mt-2 p-mr-2" name="groupname" value="semester"
                  [(ngModel)]="report.metadata?.graph.entity_field_grouped.entity_field_grouped_type"
                  label="{{'component.reports.detail.semesters' | translate}}"></p-radioButton>
                <p-radioButton *ngIf="showDateSelector" class="p-mt-2 p-mr-2" name="groupname" value="year"
                  [(ngModel)]="report.metadata?.graph.entity_field_grouped.entity_field_grouped_type"
                  label="{{'component.reports.detail.years' | translate}}"></p-radioButton>

              </div>

              <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-field p-grid" style="position: relative;">
                <label for="code" class="p-col-12">{{'component.reports.detail.order_by' | translate}} </label>
                <p-dropdown class="p-col-8" placeholder="{{'general.select' | translate}}"
                  [(ngModel)]="report.metadata?.graph?.order.order_by" [options]="entitiesToFilter" [showClear]="true"
                  name="selectedColumns" appendTo="body" [filter]="true" optionLabel="description" optionValue="id"
                  [ngModelOptions]="{standalone: true}" (onChange)="onShortChange($event)"></p-dropdown>
                <p-dropdown class="p-col-4" appendTo="body" [options]="orderByOptions"
                  [(ngModel)]="report.metadata?.graph.order.order_direction"
                  placeholder="{{'general.select' | translate}}" optionLabel="name" optionValue="value"
                  [showClear]="true" name="orderByOptions"></p-dropdown>

              </div>

              <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-field" style="position: relative;">
                <label for="code">{{'component.reports.detail.group_by2' | translate}}</label>
                <p-dropdown placeholder="{{'general.select' | translate}}"
                  [disabled]="!report.metadata?.graph.entity_field_grouped.entity_field_grouped_id"
                  [(ngModel)]="report.metadata?.graph?.entity_field_grouped2.entity_field_grouped_id"
                  [options]="entitiesToFilter" [showClear]="true" name="selectedColumns" appendTo="body" [filter]="true"
                  optionLabel="description" optionValue="id" [ngModelOptions]="{standalone: true}"
                  (onChange)="onGraphChange($event,true,true)"></p-dropdown>
              </div>
              <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-field">
                <label for="visualize">{{'component.reports.detail.visualize' | translate}}</label>
                <p-dropdown [options]="visualize_options" [(ngModel)]="report.metadata?.graph.visualize"
                  placeholder="{{'general.select' | translate}}" optionLabel="name" optionValue="value"
                  [showClear]="true" appendTo="body" [ngModelOptions]="{standalone: true}">
                </p-dropdown>
              </div>
              <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-field"
                *ngIf="report.metadata?.graph.visualize != 'count'">
                <label for="code">{{'component.reports.detail.perform_on' | translate}} </label>
                <p-cascadeSelect class="" [(ngModel)]="report.metadata?.graph.entity_field.entity_field_id"
                  [options]="entitiesToFilterNumber" optionLabel="description" optionValue="id"
                  optionGroupLabel="entity" [ngModelOptions]="{standalone: true}"
                  (onChange)="onGraphChange($event,false,false)" [optionGroupChildren]="['fields']"
                  placeholder="{{'general.select' | translate}}">
                </p-cascadeSelect>
              </div>
            </div>
          </div>
        </p-accordionTab>
        <p-accordionTab header="Mapa" *ngIf="view_map_info && showMap && report.entity_id">
          <p-header>
            <div class="">
              <p-checkbox inputId="showMapSection" [(ngModel)]="report.metadata?.map.show" [binary]="true"
                name="showMapSection"></p-checkbox>
              <label for="showMapSection" class="p-ml-2">{{'component.reports.detail.map' | translate}}</label>
            </div>
          </p-header>
        </p-accordionTab>
      </p-accordion>
      <div class="p-mt-4 buttonsReport">
        <div class="p-d-flex buttons">
          <p-button label="{{'component.reports.detail.delete' | translate}}"
            *ngIf="report.id != 0 && report.id != null && write_report" icon="pi pi-trash"
            styleClass="p-button-danger p-mr-2 p-button " (click)="delete()"></p-button>
          <div class="flex-grow-1"></div>
          <p-button (click)="openReportClonator()" styleClass="p-mr-5 p-button-help" *ngIf="create_reports"><i
              class="far fa-copy"></i>&nbsp;{{'component.reports.detail.duplicate_report' | translate}} </p-button>

          <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
            styleClass="p-button-link p-button-secondary p-ml-2 p-button " (click)="cancel()"></p-button>
          <p-button *ngIf="write_report" label="{{'general.action.save' | translate}}" icon="pi pi-check"
            styleClass="p-ml-2" (click)="save()"></p-button>

        </div>
      </div>
    </form>
  </p-tabPanel>

  <p-tabPanel [disabled]="!report.metadata?.report.show">
    <ng-template pTemplate="header">
      <i class="fas fa-table"></i>&nbsp;{{'component.reports.detail.report' | translate}}&nbsp;
    </ng-template>
    <p-card styleClass="p-card-content-fit">
      <ng-template pTemplate="header">
        <div class="p-card-title">
          {{'component.reports.detail.results' | translate}}
          <div class="p-card-title-actions  p-field">
            <p-multiSelect *ngIf="write_report" [options]="entitiesToTable" [(ngModel)]="selectedColumns"
              optionLabel="description"
              selectedItemsLabel="{0} {{'component.reports.detail.columns_selected' | translate}}"
              [style]="{'min-width': '200px'}" placeholder="{{'general.select' | translate}}"
              [ngModelOptions]="{standalone: true}"></p-multiSelect>
            <button *ngIf="write_report" pButton (click)="addColumns(true)" class="p-mr-3">
              <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> {{'component.reports.detail.add' | translate}}
            </button>
            <a (click)="changeFavourite('data')" class="p-pt-1" style="width: 100%"><i *ngIf="favouriteData"
                class="fas fa-heart"></i><i *ngIf="!favouriteData"
                class="far fa-heart"></i>&nbsp;{{'component.reports.detail.select_as_favourite' | translate}} </a>
            <p-splitButton #ap label="{{'component.configure.actions' | translate}}" icon="pi pi-cog"
              [model]="itemsAction" appendTo="body" (onClick)="ap.onDropdownButtonClick();"></p-splitButton>
          </div>
        </div>
      </ng-template>
      <div class="p-grid">
        <div class="p-col-12">
          <p-table #grid [lazy]="true" [value]="rows" [columns]="results.labels" (onColReorder)="onColReorder($event)"
            [totalRecords]="totalRows" [paginator]="true" [rows]="500" [loading]="loading"
            currentPageReportTemplate="{{resumen_table_records}}" [rowsPerPageOptions]="[500, 1000, 3000, 5000]"
            (onLazyLoad)="onReportTabOpen($event)" [showCurrentPageReport]="true" [reorderableColumns]="true"
            [responsive]="true" responsiveLayout="stack" styleClass="p-datatable-striped">
            <ng-template pTemplate="header" let-item>
              <tr>
                <th width="20"></th>
                <th *ngFor="let header of results.labels" pReorderableColumn>
                  <a (click)="orderBy(header.column_key)" class="p-pt-3 p-pb-3"
                    *ngIf="this.report.metadata.report.order?.column_key==header.column_key">
                    {{ header.description }}
                    <fa-icon [icon]="['fas', 'arrow-up']" *ngIf="this.sortDirection=='desc'"></fa-icon>
                    <fa-icon [icon]="['fas', 'arrow-down']" *ngIf="this.sortDirection=='asc'"></fa-icon>
                  </a>
                  <a (click)="orderBy(header.column_key)" class="p-pt-3 p-pb-3"
                    *ngIf="this.report.metadata.report.order?.column_key!= header.column_key">{{ header.description
                    }}</a>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-index="rowIndex" let-rowData>
              <tr [pReorderableRow]="index">
                <td width="20"><a (click)="openItem(item)"><fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon></a>
                </td>
                <td *ngFor="let header of results.labels"
                  [title]="getRowOnlyValue(header, item[header.column_key], item, true)"
                  [innerHTML]="getRowValue(header, item[header.column_key], item)">
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr *ngIf="results.labels!=null">
                <td [attr.colspan]="results.labels.length + 1">
                  {{'general.not_data' | translate }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </p-card>
  </p-tabPanel>

  <p-tabPanel [disabled]="!report.metadata?.graph.show || report.id == 0 || !graphics_in_reports">
    <ng-template pTemplate="header">
      <i class="fas fa-chart-bar"></i>&nbsp;{{'component.reports.detail.graphic' | translate}}&nbsp;
    </ng-template>
    <div class="p-col-12 p-md-12">
      <p-card header="{{report.name}}">
        <ng-template pTemplate="header">
          <div class="p-card-title">
            <div class="p-card-title-actions">
              <p-checkbox class="p-mt-2 p-mr-2" *ngIf="showInHome" id="showInHome" name="showInHome"
                label="{{'component.reports.detail.show_in_home' | translate}}" [(ngModel)]="graphForHome" binary="true"
                inputId="showInHome" (click)="graphHome()"></p-checkbox>
              <a (click)="changeFavourite('graph')"><i *ngIf="favouriteGraph" class="fas fa-heart"></i><i
                  *ngIf="!favouriteGraph"
                  class="far fa-heart"></i>&nbsp;{{'component.reports.detail.select_as_favourite' | translate}} </a>
            </div>
          </div>
        </ng-template>
        <div class="p-grid">
          <div class="p-col-12 p-lg-6 chart-container">
            <p-chart #chartGraph type="{{chart_type}}" [data]="dataGraph" [options]="basicOptions"
              height="300"></p-chart>
          </div>
          <div class="p-col-12 p-lg-6">

            <p-table [value]="dataGraphTable" [responsive]="true" responsiveLayout="stack"
              styleClass="p-datatable-striped">
              <ng-template pTemplate="header">
                <tr>
                  <th
                    [innerHTML]="getGraphInfo(report.metadata?.graph.entity_field_grouped?.entity_field_grouped_id, null, false)">
                  </th>
                  <th *ngIf="report.metadata?.graph.entity_field_grouped2.entity_field_grouped_id"
                    [innerHTML]="getGraphInfo(report.metadata?.graph.entity_field_grouped2?.entity_field_grouped_id, null, false)">
                  </th>
                  <th [innerHTML]="getGraphInfo(report.metadata?.graph.entity_field?.entity_field_id, null, false)">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item>
                <tr>
                  <td>{{item.label}}</td>
                  <td *ngIf="report.metadata?.graph.entity_field_grouped2.entity_field_grouped_id">{{item.label2}}</td>
                  <td
                    [innerHTML]="getGraphInfo(report.metadata?.graph.entity_field?.entity_field_id, item.value, true)">
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr>
                  <td style=" padding-left: 6px;">Total</td>
                  <td *ngIf="report.metadata?.graph.entity_field_grouped2.entity_field_grouped_id"></td>
                  <td style=" padding-left: 6px; ">{{graph_entity_field_total}}</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="dataGraphTable.length + 1">
                    {{'general.not_data' | translate }}
                  </td>
                </tr>
              </ng-template>
            </p-table>


          </div>
        </div>
      </p-card>
    </div>
  </p-tabPanel>

  <p-tabPanel [disabled]="!report.metadata?.map.show" *ngIf="view_map_info && showMap && report.entity_id != null">
    <ng-template pTemplate="header">
      <i class="fas fa-map-marker"></i>&nbsp;{{'component.reports.detail.map' | translate}}&nbsp;
    </ng-template>
    <div class="p-col-12 p-md-12">
      <p-card styleClass="">
        <ng-template pTemplate="header">
          <div class="p-card-title p-mb-3">
            <div class="p-card-title-actions">
              <a (click)="changeFavourite('map')"><i *ngIf="favouriteMap" class="fas fa-heart"></i><i
                  *ngIf="!favouriteMap" class="far fa-heart"></i>&nbsp;{{'component.reports.detail.select_as_favourite'
                | translate}} </a>
            </div>
          </div>
        </ng-template>
        <div class="p-grid">
          <div class="p-col-12">
            <!--<p-gmap #gmap [options]="options" [overlays]="overlays" (onMapReady)="setMap($event)"
          [style]="{ width: '100%', height: '600px' }"></p-gmap>-->
            <!-- google-maps-demo.component.html -->
            <google-map height="400px" width="750px" [center]="center" [zoom]="zoom">
              <map-marker #markerElem="mapMarker" *ngFor="let marker of markerPositions" [position]="marker.position"
                [title]="marker.title" [options]="marker.options" (mapClick)="generateInfoWindow(markerElem, marker)">
              </map-marker>
              <map-info-window>
                <div [innerHTML]="infoContent"></div>
              </map-info-window>

            </google-map>
          </div>
        </div>
      </p-card>
    </div>
  </p-tabPanel>


  <p-tabPanel header="Permisos" *ngIf="write_report">
    <ng-template pTemplate="header">
      <i class="fas fa-key"></i>&nbsp;{{'component.reports.detail.permissions' | translate}}&nbsp;
    </ng-template>
    <p-accordion>
      <p-accordionTab header="{{'component.reports.detail.zones' | translate}}" *ngIf="zonesPermission">
        <div class="p-grid">
          <div class="p-col-12 p-field">
            <p-dropdown appendTo="body" [options]="zones" [(ngModel)]="selected_zone" optionLabel="name"
              placeholder="{{'component.reports.detail.select_zone' | translate}}" (onChange)="save_zone()"
              [disabled]="zones.length == 0">
            </p-dropdown>
          </div>
          <!--
          <div class="p-col-2 p-field">
              <button pButton class="p-button-primary" label="Añadir zona"></button>
          </div>
          -->
          <div class="p-col-12">
            <p-table #grid [value]="selected_zones">
              <ng-template pTemplate="header">
                <tr>
                  <th width="100">ID</th>
                  <th>{{'component.reports.detail.name' | translate}}</th>
                  <th width="100"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-zone>
                <tr>
                  <td>{{zone.id}}</td>
                  <td>{{zone.name}}</td>
                  <td class="grid-actions">
                    <a (click)="unlinkZone(zone)" class="color-danger">
                      <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                    </a>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="3">
                    {{'general.not_data' | translate }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab header="{{'component.reports.detail.roles' | translate}}">
        <div class="p-grid">
          <div class="p-col-12 p-field">
            <p-dropdown appendTo="body" [options]="roles" [(ngModel)]="selected_role" optionLabel="name"
              placeholder="{{'component.reports.detail.select_rol' | translate}}" (onChange)="save_role()"
              [disabled]="roles.length == 0">
            </p-dropdown>
          </div>
          <div class="p-col-12">
            <p-table #grid [value]="selected_roles">
              <ng-template pTemplate="header">
                <tr>
                  <th width="100">ID</th>
                  <th>{{'component.reports.detail.name' | translate}}</th>
                  <th width="100"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-role>
                <tr>
                  <td>{{role.id}}</td>
                  <td>{{role.name}}</td>
                  <td class="grid-actions">
                    <a (click)="unlinkRole(role)" class="color-danger">
                      <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                    </a>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="3">
                    {{'general.not_data' | translate }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab header="{{'component.reports.detail.users' | translate}}">
        <div class="p-grid">
          <div class="p-col-12 p-field">
            <p-dropdown appendTo="body" [options]="users" [(ngModel)]="selected_user" optionLabel="name"
              placeholder="{{'component.reports.detail.select_user' | translate}}" (onChange)="save_user()"
              [disabled]="users.length == 0">
            </p-dropdown>
          </div>
          <div class="p-col-12">
            <p-table #grid [value]="selected_users">
              <ng-template pTemplate="header">
                <tr>
                  <th width="100">ID</th>
                  <th>{{'component.reports.detail.name' | translate}}</th>
                  <th>{{'component.reports.detail.mail' | translate}}</th>
                  <th width="100"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-user>
                <tr>
                  <td>{{user.id}}</td>
                  <td>{{user.name}}</td>
                  <td>{{user.email}}</td>
                  <td class="grid-actions">
                    <a (click)="unlinkUser(user)" class="color-danger">
                      <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                    </a>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="4">
                    {{'general.not_data' | translate }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
    <div class="p-mt-4 buttonsReport">
      <div class="p-d-flex buttons">
        <p-button label="{{'general.action.delete' | translate}}"
          *ngIf="report.id != 0 && report.id != null && write_report" icon="pi pi-trash"
          styleClass="p-button-danger p-mr-2 p-button " (click)="delete()"></p-button>
        <div class="flex-grow-1"></div>
        <p-button (click)="openReportClonator()" styleClass="p-mr-5 p-button-help" *ngIf="create_reports"><i
            class="far fa-copy"></i>&nbsp;{{'component.reports.detail.duplicate_report' | translate}} </p-button>

        <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
          styleClass="p-button-link p-button-secondary p-ml-2 p-button " (click)="cancel()"></p-button>
        <p-button *ngIf="write_report" label="{{'general.action.save' | translate}}" icon="pi pi-check"
          styleClass="p-ml-2" (click)="save()"></p-button>

      </div>
    </div>
  </p-tabPanel>
</p-tabView>