import { Component, ViewChild } from '@angular/core';
import { TaskStatusService } from '../task-status.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';

@Component({
  selector: 'app-task-status-list',
  templateUrl: './task-status-list.component.html',
  styleUrls: ['./task-status-list.component.scss']
})
export class TaskStatusListComponent {
  @ViewChild("grid") public grid: Table;
  public taskStatusFilters: any = {};
  public taskStatus: any[];
  public totalRecords: number;
  public loading: boolean = false;
  private lastGridEvent: any;

  public first;
  public last;
  constructor(
    private taskStatusService: TaskStatusService,
    private router: Router,
    private coreDialogService: CoreDialogService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void { }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  clearFilters() {
    this.taskStatusFilters = {};
    this.search();
  }

  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "order",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc"
    };
    params = Object.assign(params, this.taskStatusFilters);
    this.taskStatusService.all(params).subscribe({
      next: (data: any) => {
        this.totalRecords = data.totalRows;
        this.taskStatus = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }

  edit(taskStatus) {
    this.router.navigate(['/configuration/taskStatus/', taskStatus.id]);
  }

  delete(taskStatus) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.taskStatus.delete_message", { name: taskStatus.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.taskStatusService.delete(taskStatus.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });

            this.loadData(this.lastGridEvent);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    });
  }

}
