import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { CoreFormService } from 'src/app/core/forms/core-forms.service';
import { TaskStatusService } from '../task-status.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-task-status-detail',
  templateUrl: './task-status-detail.component.html',
  styleUrls: ['./task-status-detail.component.scss']
})
export class TaskStatusDetailComponent implements OnInit {
  @ViewChild("formDetails") public formDetails: NgForm;
  public taskStatus: any = {
    name: ""
  };
  public init_default = false;
  constructor(
    private taskStatusService: TaskStatusService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private coreFormService: CoreFormService,
    private coreDialogService: CoreDialogService,
    private _location: Location,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.loadData(params['id']);
    });
  }

  loadData(id: number) {
    if (id != 0) {
      this.taskStatusService.get(id).subscribe({
        next: (data: any) => {
          this.taskStatus = data;
          this.init_default = this.taskStatus.is_default
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      this.taskStatus.css_class = "secondary";
      this.taskStatus.color = "#82868b"
    }
  }

  cancel() {
    this.router.navigate(['/configuration/taskStatus']);
  }

  save() {
    if (this.init_default != true && this.taskStatus.is_default == true) {
      //Si empieza como false y se cambia a true...
      this.coreDialogService.confirm({
        message: this.translateService.instant("component.taskStatus.change_default"),
        header: this.translateService.instant("general.confirmation_change_default"),
        icon: "pi pi-info-circle",
        accept: () => {
          this.saveProcess();
          return true;
        },
        reject: () => {
          return false
        }
      });
    } else {
      this.saveProcess();
      return true;
    }
  }

  saveProcess() {
    if (this.coreFormService.validate(this.formDetails)) {
      this.taskStatusService.save(this.taskStatus.id, this.taskStatus).subscribe({
        next: (data: any) => {
          var _msg = (this.taskStatus.id != 0 && typeof this.taskStatus.id !== "undefined") ? "save_correct" : "add_correct";
          var aux = this.translateService.instant("component.taskStatus.detail." + _msg);
          this.messageService.add({ closable: false, severity: 'success', summary: aux });
          this.router.navigate(['/configuration/taskStatus']);
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.taskStatus.delete_message", { name: this.taskStatus.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.taskStatusService.delete(this.taskStatus.id).subscribe({
          next: (res: any) => {
            this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("general.confirmation_delete") });
            this._location.back();
            //this.router.navigate(['/configuration/taskStatus']);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    });
  }

  isDuplied() {
    var errorMessage = "";
    if (this.taskStatus.name.length <= 0) {
      //errorMessage += "El nombre no puede estar vacío. \n";
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: this.translateService.instant("general.form_incomplete") });
    }
    /*if(errorMessage){
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: errorMessage });
    }*/
    this.taskStatusService.isDuplied(this.taskStatus).subscribe(
      data => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.taskStatus.detail.types_duplicates"),
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save();
        }
      }
    );
  }
}
