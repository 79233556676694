import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ReportsService } from '../../reports.service';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { EntitiesService } from 'src/app/configuration/entities.service';
import { TasksActionTypesService } from 'src/app/tasks/tasks-action-types.service';
import { UsersService } from 'src/app/users/users.service';
import { LoginService } from 'src/app/login/login.service';
import { CoreFormService } from 'src/app/core/forms/core-forms.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CampaignsService } from 'src/app/campaigns/campaigns.service';

@Component({
  selector: 'app-report-create-task',
  templateUrl: './report-create-task.component.html',
  styleUrls: ['./report-create-task.component.scss']
})
export class ReportCreateTaskComponent implements OnInit {
  @ViewChild("formDetails") public formDetails: NgForm;
  public myForm = new UntypedFormGroup({
    task_action_id: new UntypedFormControl('', Validators.required),
    task_action_description: new UntypedFormControl(''),
    responsible_user_id: new UntypedFormControl('', Validators.required),
    task_action_scheduled_date: new UntypedFormControl('', Validators.required),
    task_action_scheduled_date_end: new UntypedFormControl(''),
    selected_campaign_id: new UntypedFormControl(''),
    add_organizations_checkbox: new UntypedFormControl(''),
    add_contacts_checkbox: new UntypedFormControl('')
  });
  public organizations: any = [];
  public selected: any[] = [];
  public tasksActionTypes: any = [];
  public responsibles: any = [];
  public connectedUser: any = {};
  public action: any = {
    task_action_scheduled_date: new Date()
  };
  public task: any = {
    responsible_user_id: parseInt(this.loginService.get("userId")),
    organizationIds: []
  };
  public results: any = [];
  public totalRows: number = 0;
  public rows: any = Array<any>();
  public entity: any = {};
  public reportId: number;
  public create_multiple_task: string;

  public selected_campaign_id: any;
  public campaigns: any[] = [];
  public add_organizations_checkbox: boolean = false;
  public add_contacts_checkbox: boolean = false;
  public visible_organizations_checkbox: boolean;
  public visible_contacts_checkbox: boolean;
  public campaigns_permission: boolean;


  public prevTotalRows: number;

  constructor(private route: ActivatedRoute,
    private campaignsService: CampaignsService,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private coreDialogService: CoreDialogService,
    private datePipe: DatePipe,
    private reportsService: ReportsService,
    private entitiesService: EntitiesService,
    private coreFormService: CoreFormService,
    private tasksActionTypesService: TasksActionTypesService,
    private loginService: LoginService,
    private usersService: UsersService, private translateService: TranslateService) { }

  ngOnInit() {
    this.prevTotalRows = this.config.data.totalRows;
    this.campaigns_permission = this.loginService.hasPermission("REPORTS_ACTIONS_CAMPAIGNS");
    this.route.params.subscribe(params => {
      this.loadData(this.config.data.reportId, this.config.data.reportResults, this.config.data.entity);
    });
    this.loadTasksActionTypes();
    this.loadCampaigns();
    this.loadResponsibles();
  }

  loadData(reportId: number, results: any, entity: any) {
    this.reportId = reportId;
    this.results = results;
    this.rows = this.results.values;
    this.totalRows = this.rows.length;
    this.entity = entity;
    if (this.prevTotalRows > 500) {
      this.selected = this.results.baseIds;
    } else {
      //this.selected = this.results.values.map(m => m.base_id + "");
      this.selected = this.rows.map(m => m.base_id + "");
    }
    this.revealCheckboxes();


    this.create_multiple_task = this.translateService.instant("component.reports.detail.create_multiple_task", { selectedLength: this.selected.length });
    var now = new Date();
    var nowMultiple = now.setMinutes(Math.ceil(now.getMinutes() / 15) * 15);

    this.myForm.patchValue({
      responsible_user_id: this.task.responsible_user_id,
      task_action_id: null,
      task_action_description: null,
      task_action_scheduled_date: new Date(nowMultiple),
      task_action_scheduled_date_end: null,
    });
  }

  getCalendarInputClass(type): string {
    switch (type) {
      case 0:
        return this.myForm.get('task_action_scheduled_date').invalid && this.myForm.get('task_action_scheduled_date').touched ? 'ng-invalid' : '';
      case 1:
        return this.myForm.get('task_action_scheduled_date_end').invalid && this.myForm.get('task_action_scheduled_date_end').touched ? 'ng-invalid' : '';
    }
  }

  loadTasksActionTypes() {
    this.tasksActionTypesService.all({}).subscribe(
      data => {
        this.tasksActionTypes = data.rows;
      }
    )
  }
  loadResponsibles() {
    this.usersService.all({}).subscribe({
      next: (data: any) => {
        this.responsibles = data.rows;
        this.responsibles.unshift({ id: -2, name: this.translateService.instant("component.reports.detail.entity_responsible") });
        this.responsibles.unshift({ id: -1, name: this.translateService.instant("component.reports.detail.organization_responsible") });
      },
      error: (error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    var d2 = date2.getTime();
    var d1 = date1.getTime() - (date1.getTime() % 86400000);

    const diffInTime = d2 - d1;

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays + 1;

  }

  getTotal() {
    if (this.prevTotalRows > 500) {
      this.create_multiple_task = this.translateService.instant("component.reports.detail.create_multiple_task", { selectedLength: this.prevTotalRows });
    } else {
      this.create_multiple_task = this.translateService.instant("component.reports.detail.create_multiple_task", { selectedLength: this.selected.length });
    }
    return this.create_multiple_task;
  }

  getTotalPerDay() {
    let numDays = 1;
    if (this.prevTotalRows > 500) return this.translateService.instant("component.reports.num_total_tasks_create", { numTasks: this.prevTotalRows / numDays });
    if (this.myForm.get('task_action_scheduled_date').value != null) {
      if (this.myForm.get('task_action_scheduled_date_end').value != null) {

        numDays = this.getNumberOfDays(this.myForm.get('task_action_scheduled_date').value, this.myForm.get('task_action_scheduled_date_end').value);
      }
      if (Number.isInteger(this.selected.length / numDays)) {
        return this.translateService.instant("component.reports.num_total_tasks_create", { numTasks: this.selected.length / numDays });
      } else {
        return this.translateService.instant("component.reports.num_total_tasks_equitable", { numDays: numDays });
      }
    }

  }

  validar() {
    if (this.task.task_action_id == null || this.task.task_action_id == 0) return false;
    if (this.task.responsible_user_id == null || this.task.responsible_user_id == 0) return false;
    if (this.task.task_action_scheduled_date == null) return false;
    if (this.task.task_action_scheduled_date_end == null) return false;
    if (this.task.task_action_description == null) return false;
    return true;
  }

  save() {
    if (this.validar() == false) {
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: this.translateService.instant("component.reports.detail.all_fields_same_value") });
      return;
    }
    if (this.task.task_action_scheduled_date != null) this.task.task_action_scheduled_date = new Date(this.task.task_action_scheduled_date).toISOString();
    let organizationsIds = this.selected.map(m => m.id);
    this.reportsService.addMultiplesTasks(this.task, this.selected, this.reportId, organizationsIds, this.selected_campaign_id, this.add_organizations_checkbox, this.add_contacts_checkbox).subscribe({
      next: (res: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.detail.add_tasks_correct") });
        this.dynamicDialogRef.close("success");
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
    this.dynamicDialogRef.close();
  }
  cancel() {
    this.dynamicDialogRef.close();
  }

  getRowValue(header, value = "", item) {

    if (typeof (this.entity.fields) !== "undefined") {

      let field = this.entity.fields.find(m => m.id == header.entity_field_id);
      let property = field.model_property;
      if (field.model_property_label != null && field.model_property_label != "") property = field.model_property_label;

      let formatFn = field.formatFn;
      if (formatFn == null && field.configuration != null && field.configuration.formatFn != null) {
        formatFn = field.configuration.formatFn;
      }
      if (formatFn != null) {
        var format = formatFn.split("|");
        if (format[0] == "date") value = this.formatDate(value, format);
        if (format[0] == "suffix") value = this.formatSuffix(value, format);
        if (format[0] == "phone") value = this.formatPhone(value, format);
        if (format[0] == "mail") value = this.formatMail(value, format);
        if (format[0] == "www") value = this.formatWWW(value, format);
        //if (format[0] == "badge") value = this.formatBadge(field, value, format);
        if (format[0] == "image") value = this.formatImage(field, format);
        if (format[0] == "currency") value = this.formatCurrency(value, format);
        if (format[0] == "number") value = this.formatNumber(value, format);
      }
      if (value == null || value == "") value = " ";
    }
    return value;
  }

  formatDate(value: any, args: any[]) {
    return this.datePipe.transform(value, args[1]);
  }
  formatSuffix(value: any, args: any[]) {
    return value + args[1];
  }
  formatPhone(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = "<a href='tel:" + value + "'>" + value + "</a>";
    return formattedValue;
  }
  formatMail(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = "<a href='mailto:" + value + "'>" + value + "</a>";
    return formattedValue;
  }
  formatWWW(value: any, args: any[]) {
    if (value == null) return "";
    if (!value.startsWith("http")) {
      value = "https://" + value;
    }
    var formattedValue = "<a href='" + value + "' target='_blank'>" + value + "</a>";
    return formattedValue;
  }
  formatBadge(field: any, value: any, args: any[]) {
    //TODO: Falta revisar este.
    if (value == null) return "";
    let className = "";
    let subClassName = "";
    if (field) {
      let refValue = "";
      if (args[1] != "") {
        try {
          let valorID = eval("row." + field.model_property);
          subClassName = args[1] + valorID;
        } catch (e) {
          console.error(`Error evaluando this.model.${field.entityField.model_property}`);
        }
      }
      className = "badge badge-block " + subClassName;
      var formattedValue = "<div class='" + className + "'>" + value + "</div>";
    }
    return formattedValue;
  }
  formatImage(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = `<div class="d-flex justify-content-left align-items-center"><div class="avatar-wrapper"><div class="avatar  me-1"><img src="../../../app-assets/images/avatars/2-small.png" alt="Avatar" height="32" width="32"></div></div><div class="d-flex flex-column"><a href="app-user-view.html" class="user_name text-truncate"><span class="fw-bold">Zsazsa McCleverty</span></a><small class="emp_post text-muted">@zmcclevertye</small></div></div>`;
    return formattedValue;
  }
  formatCurrency(value: any, args: any[]) {
    if (value == null) return "0 €";
    var formattedValue = new Intl.NumberFormat(environment.companyConfiguration.locale, { style: "currency", currency: environment.companyConfiguration.currency, useGrouping: true }).format(value);
    return formattedValue;
  }
  formatNumber(value: any, args: any[]) {
    if (value == null) return "0";
    var formattedValue = new Intl.NumberFormat(environment.companyConfiguration.locale).format(value)
    return formattedValue;
  }

  onSubmit() {
    this.myForm.markAllAsTouched();
    let organizationsIds = this.selected.map(m => m.id);
    if (this.myForm.valid == false) {
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    //let organizationsIds = this.selected.map(m => m.id);

    Object.assign(this.task, this.myForm.value);
    this.reportsService.addMultiplesTasks(this.task, this.selected, this.reportId, organizationsIds, this.selected_campaign_id, this.add_organizations_checkbox, this.add_contacts_checkbox).subscribe({
      next: (res: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.detail.add_tasks_correct") });
        this.dynamicDialogRef.close("success");
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
    this.dynamicDialogRef.close();
  }

  loadCampaigns() {
    this.campaignsService.all({}).subscribe({
      next: (data: any) => {
        this.campaigns = data.rows;
        this.campaigns.unshift({ name: this.translateService.instant("component.reports.detail.none") });
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  revealCheckboxes() {
    switch (this.entity.code) {
      case "contact":
        this.visible_contacts_checkbox = false;
        this.visible_organizations_checkbox = true;
        break;
      case "organization":
        this.visible_contacts_checkbox = false;
        this.visible_organizations_checkbox = false;
        break;
      case "task":
        this.visible_contacts_checkbox = true;
        this.visible_organizations_checkbox = true;
        break;
      case "oportunity":
        this.visible_contacts_checkbox = true;
        this.visible_organizations_checkbox = true;
        break;
      case "sale":
        this.visible_contacts_checkbox = true;
        this.visible_organizations_checkbox = true;
        break;
      case "transaction":
        this.visible_contacts_checkbox = true;
        this.visible_organizations_checkbox = true;
        break;
      case "meeting":
        this.visible_contacts_checkbox = true;
        this.visible_organizations_checkbox = true;
        break;
      case "notifications":
        this.visible_contacts_checkbox = false;
        this.visible_organizations_checkbox = false;
        break;
      default:
        this.visible_contacts_checkbox = false;
        this.visible_organizations_checkbox = false;
        break;
    }
  }
}
