import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MailchimpService } from 'src/app/configuration/integration/mailchimp.service';
import { NotificationsService } from 'src/app/notifications/notifications.service';

@Component({
  selector: 'app-mail-marketing-test',
  templateUrl: './mail-marketing-test.component.html',
  styleUrls: ['./mail-marketing-test.component.scss']
})
export class MailMarketingTestComponent implements OnInit {
  public marketingTest: any = {};
  public errorMessage: string = "";
  public notification:any = null;
  

  constructor( private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef, 
    private notificationsService: NotificationsService,
    public config: DynamicDialogConfig,
    private mailchimpService:MailchimpService,
    private translateService: TranslateService,
    ) { }

  ngOnInit() {
    this.errorMessage = "";
    this.marketingTest.id = this.config.data.notificationId;
    this.notificationsService.get(this.marketingTest.id).subscribe(
      data => {
        this.notification = data;
        if(this.notification.type == 'mailchimp' && this.notification.external_id==null){
          this.errorMessage = this.translateService.instant('component.mailMarketing.test.mailchimp_campaign_not_synchronized');
        }
      },
      error => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    );
  }

  send(){
    if(this.notification.type == "mailchimp"){
      //enviamos via mailchimp
      this.mailchimpService.sendCampaignTest(this.marketingTest.id, this.marketingTest.email).subscribe(
        data => {
          this.dynamicDialogRef.close(); 
        },
        error => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      );
    }else{
      //envio de correo normal
      this.notificationsService.sendTest(this.marketingTest.id, this.marketingTest.email).subscribe(
        data => {
          this.dynamicDialogRef.close(); 
        },
        error => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      );
    }
  }
  
  cancel() {
    this.dynamicDialogRef.close();
  }

}
