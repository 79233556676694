import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UtilsService } from '../../../core/utils.service';
import { CompaniesIntegrationsService } from '../companiesintegrations.service';
import { OriginsService } from '../../origins/origins.service';
import { TasksActionTypesService } from 'src/app/tasks/tasks-action-types.service';
import { UsersService } from '../../../users/users.service';
import { TasksStatusService } from '../../../tasks/tasks-status.service';
import { OrganizationsService } from '../../../organizations/organizations.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-integration-edit-webform',
  templateUrl: './integration-edit-webform.component.html',
  styleUrls: ['./integration-edit-webform.component.scss']
})
export class IntegrationEditWebformComponent implements OnInit {

  public task_actions: any[] = [];
  public responsibles: any[] = [];
  public task_status: any[] = []
  public type_process_options: any[] = [
    { label: this.translateService.instant('component.integrations.type_process_assign'), value: 'assign' },
    { label: this.translateService.instant('component.integrations.type_process_create'), value: 'create' }
  ]
  public organizations: any[];
  public integration: any = {
    configuration: {}
  };
  public selectedOrganizationId: number = null;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private companiesIntegrationsService: CompaniesIntegrationsService,
    private messageService: MessageService,
    private utilsService: UtilsService,
    private taskActionsTypesService: TasksActionTypesService,
    private tasksStatusService: TasksStatusService,
    private responsiblesService: UsersService,
    private organizationService: OrganizationsService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.loadTaskCombos();
    this.companiesIntegrationsService.getByCode("webform").subscribe(
      data => {
        console.log(data);
        if (data != null) {
          if (data.configuration == null) {
            data.configuration = {
              recaptcha: { enabled: false }
            };
          }

          this.integration = data;
          this.selectedOrganizationId = this.integration.configuration.organization_id;
          this.loadOrganizations(null);
        }
        if (this.integration.configuration.url == null || this.integration.configuration.url == "") {
          var b64 = btoa(localStorage.getItem("companyId") + ";" + this.utilsService.randomString(10));
          this.integration.configuration.url = location.protocol + "//" + location.host + "/api/webforms/leads/" + b64 + "/create";
        }
      },
      error => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    );
  }
  loadTaskCombos() {
    this.taskActionsTypesService.all({}).subscribe({
      next: (data: any) => {
        this.task_actions = data.rows;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    this.responsiblesService.combo({}).subscribe({
      next: (data: any) => {
        console.log(data);
        this.responsibles = data.rows;
        this.responsibles.unshift({ name: this.translateService.instant('general.undefined'), id: null });
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
    this.tasksStatusService.all({}).subscribe({
      next: (data: any) => {
        console.log(data);
        this.task_status = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  save() {
    this.companiesIntegrationsService.save(this.integration.id, this.integration).subscribe({
      next: (data: any) => {
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    });
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  loadOrganizations(event) {
    let params: any = {};
    if (event != null) params.text = event.filter;
    if (this.selectedOrganizationId != null) params.include_organization_id = this.selectedOrganizationId;
    this.organizationService.getCombo(params).subscribe({
      next: (data: any) => {
        this.organizations = data.rows;
        this.organizations.unshift({ name: this.translateService.instant('component.integrations.none'), id: null });
        //if (this.selectedOrganizationId != null) this.selectedOrganization = this.organizations.find(m => m.id == this.selectedOrganizationId);
      },
      error: (error: any) => this.messageService.add({ closable: false, severity: "error", detail: error.error.title })
    });
  }
}
