import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { AttachmentsTypesService } from 'src/app/configuration/attachments-types/attachments-types.service';
import { EntitiesService } from 'src/app/configuration/entities.service';
import { LoginService } from 'src/app/login/login.service';
import { LoaderService } from '../../../loader/loader.service';
import { ComponentService } from '../../component.service';
import { ViewsService } from '../../views.service';
import { ViewComponentConfigureComponent } from '../configure/view-component-configure.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { DebugService } from '../../../debug.service';

@Component({
  selector: 'app-view-component-files',
  templateUrl: 'view-component-files.component.html',
  styleUrls: ['view-component-files.component.scss'],
})
export class ViewComponentFilesComponent implements OnInit {

  @Output() onEvent: EventEmitter<any> = new EventEmitter();

  @Input("component") public component: any = {};
  @Input("index") public index: number = 0;
  @Input("model") public model: any = {};
  @Input("showConfigOptions") public showConfigOptions: boolean = false;
  @Input("view") public view: any = {};

  public temporalModel: any = {};
  public entity: any = {};
  private lastGridEvent: any;
  public loading: boolean = false;
  public totalRows: number = 0;
  public rows: any = Array<any>();
  public user_id: number;
  public today: string;
  public attachmentsTypes: any[];
  public prevItem_type_id: number = null;
  public width: string = "200px";
  public attachmentsTypesWithData: any[];

  public visibleByOptions: any[] = [
    { code: "all", label: this.translateService.instant("view.configure.all") },
    { code: "user", label: this.translateService.instant("view.configure.user_responsible") }
  ];

  constructor(
    public debugService: DebugService,
    private loaderService: LoaderService,
    private entitiesService: EntitiesService,
    private attachmentTypeService: AttachmentsTypesService,
    private messageService: MessageService,
    private viewsService: ViewsService,
    private datePipe: DatePipe,
    private dialogService: DialogService,
    private loginService: LoginService,
    public componentService: ComponentService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.loadAttachmentTypes();
    this.user_id = parseInt(this.loginService.get("userId"));
    this.today = new Date().toISOString();
    this.component.fields.forEach(field => {

      if (typeof field.model_property != "undefined") {
        this.temporalModel[field.model_property] = eval("this.model." + field.model_property);
      }

    });

    this.entitiesService.getByCode(this.component.entity).subscribe(
      data => {
        this.entity = data;
        var newFields: any = [];
        var user_role_id = localStorage.getItem("roleId") + "";

        this.component.fields.forEach(field => {
          var hasRole = true;
          field.entityField = this.entity.fields.find(m => m.id == field.entity_field_id);
          var viewRoles = field.entityField?.configuration['viewRoles'];
          if (viewRoles != null && viewRoles.length > 0) {
            if (!viewRoles.find(role => role == user_role_id)) hasRole = false;
          }
          if (field.entityField != null && typeof (field.entityField) !== "undefined" && !field.entityField.is_disabled && hasRole) newFields.push(field);
        });
        this.component.fields = newFields;
        if (this.component.avatar != null) {
          if (this.component.avatar.type == "initials") {
            this.component.avatar.fields.forEach(field => {
              field.entityField = this.entity.fields.filter(m => m.id == field.entity_field_id);
            });
          }
        }
        this.lazyLoad();
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    );
  }
  loadAttachmentTypes() {
    this.attachmentTypeService.all({}).subscribe(
      data => {
        this.attachmentsTypes = data.rows;
      },
      error => {
        this.messageService.add({ severity: 'error', detail: error.error.title });
      }
    );
  }
  savePrevItem(attachment) {
    this.prevItem_type_id = attachment.attachment_type_id;
    if (attachment.attachmentType != null) {
      return attachment.attachmentType.name;
    } else {
      return this.translateService.instant("files.configure.no_category");
    }
  }
  adaptWidth(length) {
    //Adapta la anchura de las cabeceras de tipos por la longitud de su nombre.
    //return this.width = (11*length)+"px";
    return "200px";
  }
  getModelValue(model, field) {
    var value = "";

    this.temporalModel[field.entityField?.model_property] = eval("model." + field.entityField?.model_property);
    value = this.temporalModel[field.entityField?.model_property];

    return value;
  }

  formatDate(value: any, args: any) {
    return this.datePipe.transform(value, args);
  }
  formatSuffix(value: any, args: any[]) {
    return value + args[1];
  }
  formatPhone(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = "<a href='phone:" + value + "'>" + value + "</a>";
    return formattedValue;
  }
  formatMail(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = "<a href='mailto:" + value + "'>" + value + "</a>";
    return formattedValue;
  }
  formatBadge(field: any, value: any, args: any[]) {
    if (value == null) return "";
    let className = "";
    let subClassName = "";
    if (field) {
      let refValue = "";
      if (args[1] != "") {
        try {
          let valorID = eval("row." + field.model_property);
          subClassName = args[1] + valorID;
        } catch (e) {
          console.error(`Error evaluando this.model.${field.entityField.model_property}`);
        }
      }
      className = "badge badge-block " + subClassName;
      var formattedValue = "<div class='" + className + "'>" + value + "</div>";

    }

    return formattedValue;
  }
  formatImage(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = `<div class="d-flex justify-content-left align-items-center"><div class="avatar-wrapper"><div class="avatar  me-1"><img src="../../../app-assets/images/avatars/2-small.png" alt="Avatar" height="32" width="32"></div></div><div class="d-flex flex-column"><a href="app-user-view.html" class="user_name text-truncate"><span class="fw-bold">Zsazsa McCleverty</span></a><small class="emp_post text-muted">@zmcclevertye</small></div></div>`;
    return formattedValue;
  }
  formatCurrency(value: any, args: any[]) {
    if (value == null) return "0 €";
    var formattedValue = new Intl.NumberFormat(environment.companyConfiguration.locale, { style: "currency", currency: environment.companyConfiguration.currency, useGrouping: true }).format(value);
    return formattedValue;
  }
  formatNumber(value: any, args: any[]) {
    if (value == null) return "0";
    var formattedValue = new Intl.NumberFormat(environment.companyConfiguration.locale).format(value)
    return formattedValue;
  }
  lazyLoad() {
    var params = {

    };

    if (this.component.source != null) {
      if (this.component.source.params != null && Array.isArray(this.component.source.params)) {
        this.component.source.params.forEach(param => {
          let value = eval(param.value);
          if (typeof (value) != "undefined" && value != null) params[param.name] = value;
        });
      }
    }

    this.loaderService.skipRequest('/api/' + this.component.source.url, params);
    this.viewsService.getTableData(this.component.source.url, params).subscribe(
      data => {
        this.totalRows = data.totalRows;
        this.rows = data.rows;
        let aux = this.rows.map(m => m.attachmentType);
        this.attachmentsTypesWithData = aux.filter((v, i) => v != null && aux.findIndex(item => item.id == v.id) === i);
        this.attachmentsTypesWithData.push({ id: null, name: this.translateService.instant("files.configure.unclassified") });
        this.loading = false;
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    )
  }

  left() {
    this.onEvent.emit({ event: "component-left", index: this.index });
  }

  right() {
    this.onEvent.emit({ event: "component-right", index: this.index });
  }

  getAvatar(row: any) {
    let ret = "";
    if (this.component.avatar != null) {
      if (this.component.avatar.type == "initials") {
        this.component.avatar.fields.forEach(field => {
          ret += eval("row." + field.entityField?.model_property).substring(0, 1).toUpperCase();
        });
      }
    }
    return ret;
  }


  getData(row: any, type) {
    let ret = "";
    var titleFields = this.component.fields.filter(m => m.type == type);
    if (titleFields.length > 0) {
      titleFields.forEach(field => {
        let property = field.entityField.model_property;
        if (field.entityField.model_property_label != null && field.entityField.model_property_label != "") property = field.entityField.model_property_label;

        var value = "";
        try {
          value = eval("row." + property);
        } catch (e) {
          //console.error("Eval for row." + property + " fail!");
        }

        if (field.type == "badge") {
          var css = field.badge.filter(m => m.id == eval("row." + field.entityField.model_property));
          if (css.length == 1) {
            let str = "<div class='p-badge p-badge-" + css[0].css + "'>" + value + "</div>";
            value = str;
          }
        } else {
          var format = field.entityField.control_type;

          if (format == "input-datetime") { value = this.formatDate(value, 'dd/MM/yyyy HH:mm'); }
          if (format[0] == "suffix") value = this.formatSuffix(value, format);
          if (format[0] == "phone") value = this.formatPhone(value, format);
          if (format[0] == "mail") value = this.formatMail(value, format);
          if (format[0] == "badge") value = this.formatBadge(field, value, format);
          if (format[0] == "image") value = this.formatImage(field, format);
          if (format[0] == "currency") value = this.formatCurrency(value, format);
          if (format[0] == "number") value = this.formatNumber(value, format);
        }
        ret += value + " ";
      });
    }
    return ret;
  }

  columns() {
    return this.component.fields.filter(m => m.type == "column");
  }

  onDblClick(item) {
    this.onEvent.emit({ event: "dblclick", component: this.component.code, data: item });
  }

  onActionClick(action, item) {
    if (action == 'edit') action = { name: "edit" };
    this.onEvent.emit({ event: "action", component: this.component.code, action: action, data: item });
  }

  getRows(attachment_type_id) {
    return this.rows.filter(m => m.attachment_type_id == attachment_type_id);
  }

  getImage(mimetype) {
    var ret = "other.png";
    if (mimetype == "application/pdf") {
      ret = "pdf.png";
    } else if (mimetype == "image/png" || mimetype == "image/jpeg" || mimetype == "image/jpg" || mimetype == "image/gif" || mimetype == "image/bmp") {
      ret = "img.png";
    } else if (mimetype == "application/msword" || mimetype == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      ret = "word.png";
    } else if (mimetype == "application/vnd.ms-excel" || mimetype == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      ret = "xls.png";
    } else if (mimetype == "application/vnd.ms-powerpoint" || mimetype == "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
      ret = "pwt.png";
    }
    return ret;
  }

  fireEvent(component: string, event: string, args: any) {
    if (event == "reload") {
      this.lazyLoad();
    }
  }

  isActionVisible(action: any, item: any) {
    let visible: boolean = true;
    if (action.ngIf != null) {
      visible = eval(action.ngIf);
    }
    return visible;
  }

  getActionLabel(action: any) {
    //return this.componentService.getActionLabel(action, this.view.code + "-" + this.component.code);
    return this.componentService.getActionLabel(action, this.component.code);
  }
}